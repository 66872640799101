import { z } from 'zod';
import { projectZod } from './Project.model';

export const tokenDistributionZod = z.object({
  id: z.string(),
  chain: z.string(),
  senderWallet: z.string(),
  receiverWallet: z.string(),
  tokensSent: z.number(),
  amountInUsd: z.number(),
  symbol: z.string(),
  tokenAddress: z.string().optional(),
  project: projectZod,
  currencyName: z.string(),
  blockHeight: z.number().optional(),
  timestamp: z.number().optional(),
  hash: z.string().optional(),
});

export type TTokenDistribution = z.infer<typeof tokenDistributionZod>;
