import { Link, styled } from '@mui/material';
import colors from '@theme/customColors';

export const LinkStyled = styled(Link)(({ theme }) => ({
  ...theme.typography.h5,
  fontsize: '16px',
  color: colors.blue,
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
  },
}));
