import { useCallback, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import useOffers from '@hooks/useOffers';
import { MainBox } from '@styles/shared/SBoxes';
import useListings from '@hooks/useListings';
import { TListingTab } from '@typescript/TTabs';
import { ListingType } from '@constants/CListings';
import ExploreTable from '@components/explore/ExploreTable';
import { Svgs } from '@utils/svgs';
import DataWrapper from '@components/shared/DataWrapper';
import { useTheme } from '@mui/material/styles';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { NO_LISTINGS_MS } from '@constants/CDefaultMessages';
import CustomTabs from '@components/explore/CustomTabs';
import { TradeModalStep } from '@constants/CTransfer';
import DeleteListingModal from '@components/modals/listings/DeleteListingModal';
import SellWTBModal from '@components/modals/listings/SellWTBModal';
import MakeOfferModal from '@components/modals/offers/MakeOfferModal';
import WtsTxModals from '@components/modals/transactions/WtsTxModals';
import { ListingAction } from '@constants/CDataActions';
import { TDataActions } from '@typescript/TDataActions';
import { Coin } from '@constants/CCoin';
import WtbTxModals from '@components/modals/transactions/WtbTxModals';
import useUser from '@hooks/useUser';
import { sellWTBZod } from '@typescript/dtos/listing/createListing.dto';
import { idZod } from '@typescript/dtos/shared/id.dto';
import {
  CreateOfferDTO,
  createOfferZod,
} from '@typescript/dtos/offer/createOffer.dto';
import { getListingByTypeZod } from '@typescript/dtos/listing/getListings.dto';
import { useGetActiveListingsQuery } from '@store/api/listingApi';
import ExploreTableResponsive from '@components/explore/ExploreTableResponsive';
import useNotification from '@hooks/useNotification';
import { getListingsColumnsWithTradeActions } from '@components/tables-columns/ListingsColumns';
import useActionOrConnect from '@hooks/useActionOrConnect';

function Explore() {
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(20);

  const [tab, setTab] = useState<TListingTab>({
    index: ListingType.SELL,
    name: 'WTS',
  });

  const {
    data: { count, resource: listings } = { count: 0, resource: [] },
    isLoading,
    isFetching,
  } = useGetActiveListingsQuery(
    getListingByTypeZod.parse({
      type: tab.index,
      page: page + 1,
      pageSize,
    }),
  );

  const handleActionOrConnect = useActionOrConnect();

  const { createOffer } = useOffers();

  const theme = useTheme();

  const { currentUser } = useUser();

  const down2Lg = useMediaQuery(theme.breakpoints.down('2lg'));

  const { notifySuccess, notifyError } = useNotification();

  const { deleteListing, sellWTB } = useListings();

  const [currentListing, setCurrentListing] = useState<TListing>();

  const [tradeModalStep, setTradeModalStep] = useState<TradeModalStep>(
    TradeModalStep.BUY,
  );

  const [action, setAction] = useState<TDataActions>({
    listingAction: null,
    offerAction: null,
  });

  const [openActionModal, setOpenActionModal] = useState<boolean>(false);

  const closeActionModal = () => {
    setOpenActionModal(false);
  };

  const sellWTBListing = async (acceptedCoin: Coin) => {
    try {
      if (!currentListing) {
        throw Error('No listing found');
      }
      await sellWTB(sellWTBZod.parse({ id: currentListing.id, acceptedCoin }));
      notifySuccess('Sell confirmation sent!');
    } catch (error) {
      notifyError('Sell confirmation failed!');
      throw error;
    }

    closeActionModal();
  };

  const makeOffer = async (offer: CreateOfferDTO) => {
    if (currentListing && currentUser) {
      try {
        await createOffer(
          createOfferZod.parse({
            offeredPrice: offer.offeredPrice,
            offeredTokenPrice: offer.offeredTokenPrice,
            offerCoin: offer.offerCoin,
            listingId: offer.listingId,
          }),
        ).unwrap();
        notifySuccess('Offer created!');
      } catch (error) {
        notifyError('Offer creation failed!');
        throw error;
      }
    }
    closeActionModal();
  };

  const handleListingActions = useCallback(
    (listing: TListing, listingAction: ListingAction) => {
      setCurrentListing(listing);
      setAction({ offerAction: null, listingAction });
      setOpenActionModal(true);
      setTradeModalStep(TradeModalStep.BUY);
    },
    [],
  );

  const memorizedToolbar = useCallback(
    () => (
      <GridToolbarContainer sx={{ mb: '0.5rem' }}>
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <CustomTabs
            handleChangeTabIndex={(currentTab: TListingTab) => {
              setTab(currentTab);
              setPage(0);
              setPageSize(20);
            }}
            tab={tab}
          />
          <GridToolbarFilterButton sx={{ p: 0, pl: '10px', mt: '10px' }} />
        </Box>
      </GridToolbarContainer>
    ),
    [tab],
  );

  const listingModals = () => {
    if (currentListing) {
      switch (action.listingAction) {
        case ListingAction.BUY_WTS:
          return (
            <WtsTxModals
              step={tradeModalStep}
              changeStep={(newStep: TradeModalStep) =>
                setTradeModalStep(newStep)
              }
              listing={currentListing}
            />
          );

        case ListingAction.BUY_WTB:
          return (
            <WtbTxModals
              step={tradeModalStep}
              changeStep={(newStep: TradeModalStep) =>
                setTradeModalStep(newStep)
              }
              listing={currentListing}
            />
          );

        case ListingAction.SELL_WTB:
          return (
            <SellWTBModal
              listing={currentListing}
              openModal={openActionModal}
              closeModal={closeActionModal}
              sellWTBListing={sellWTBListing}
            />
          );
        case ListingAction.MAKE_OFFER:
          return (
            <MakeOfferModal
              openModal={openActionModal}
              closeModal={closeActionModal}
              listing={currentListing}
              makeOffer={makeOffer}
            />
          );

        case ListingAction.DELETE:
          return (
            <DeleteListingModal
              openModal={openActionModal}
              closeModal={closeActionModal}
              listing={currentListing}
              deleteListing={async (payload) => {
                await deleteListing(idZod.parse(payload));
              }}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  const listingsContent = () => {
    if (down2Lg) {
      return (
        <ExploreTableResponsive
          tab={tab}
          handleListingActions={handleListingActions}
          currentUser={currentUser}
        />
      );
    }
    return (
      <ExploreTable
        isFetching={isFetching}
        pageSize={pageSize}
        setCurrentPage={setPage}
        setPageSize={setPageSize}
        currentPage={page}
        count={count}
        content={listings}
        columns={getListingsColumnsWithTradeActions(
          handleListingActions,
          handleActionOrConnect,
          currentUser,
        )}
        customToolbar={memorizedToolbar}
      />
    );
  };

  return (
    <MainBox
      borderColor="red"
      sx={{ maxHeight: 'calc(100vh - 58px)', height: '100%' }}
    >
      <Box
        sx={{
          display: !listings.length ? 'block' : { xs: 'block', '2lg': 'none' },
          backgroundColor: 'transparent',
          position: { xs: 'sticky', '2lg': 'relative' },
          top: 0,
          backdropFilter: 'blur(25px)',
          zIndex: 100,
        }}
      >
        <CustomTabs
          handleChangeTabIndex={(currentTab: TListingTab) => {
            setTab(currentTab);
            setPage(0);
            setPageSize(20);
          }}
          tab={tab}
        />
      </Box>
      <DataWrapper
        isLoading={isLoading}
        data={listings}
        defaultOptions={{
          icon: Svgs.IconTransactions,
          fullHeight: true,
          text: NO_LISTINGS_MS,
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: '100%', xl: '1920px' },
            mx: 'auto',
          }}
        >
          {listingsContent()}
        </Box>
      </DataWrapper>
      {currentListing && listingModals()}
    </MainBox>
  );
}

export default Explore;
