import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import { resourceWithPaginatedZod } from '@typescript/dtos/shared/pagination.dto';
import { CreateTransactionDTO } from '@typescript/dtos/transaction/createTransaction.dto';
import {
  GetTradeTxByTypeDto,
  GetTradeTxByProjectDto,
} from '@typescript/dtos/transaction/getTransaction.dto';
import { transactionZod } from '@typescript/models/TradeTransaction.model';

import Moralis from 'moralis-v1';
import { userApi } from './userApi';

export const transactionApi = createApi({
  reducerPath: ApiSlice.TRANSACTIONS,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.TRANSACTIONS],
  endpoints: (builder) => ({
    createTransaction: builder.mutation({
      async queryFn(dto: CreateTransactionDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_TRADE_TRANSACTION,
            dto,
          );
          return { data: transactionZod.parse(response) };
        } catch (error) {
          throw Error(`createTransaction: ${error}`);
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(userApi.util.invalidateTags([ApiTag.USER]));
        });
      },
      invalidatesTags: [ApiTag.TRANSACTIONS],
    }),
    confirmTradeTransaction: builder.mutation({
      async queryFn(hash: string) {
        try {
          await Moralis.Cloud.run(CloudFunctions.CONFIRM_TRADE_TRANSACTION, {
            hash,
          });
          return { data: null };
        } catch (error) {
          throw Error(`confirmTradeTransaction Error: ${error}`);
        }
      },
      onQueryStarted: (_, api) => {
        api.dispatch(userApi.util.invalidateTags([ApiTag.USER]));
      },
      invalidatesTags: [ApiTag.TRANSACTIONS],
    }),
    getMyTradeTxByType: builder.query({
      async queryFn(payload: GetTradeTxByTypeDto) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_TRADE_TX,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(transactionZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyTradeTxByType: ${error}`);
        }
      },
      providesTags: [ApiTag.TRANSACTIONS],
    }),
    getMyTradeTxByProject: builder.query({
      async queryFn(payload: GetTradeTxByProjectDto) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_TRADE_TX_BY_PROJECT,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(transactionZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyTradeTxByProject: ${error}`);
        }
      },
      providesTags: [ApiTag.TRANSACTIONS],
    }),
  }),
});

export const {
  useCreateTransactionMutation,
  useGetMyTradeTxByTypeQuery,
  useLazyGetMyTradeTxByTypeQuery,
  useConfirmTradeTransactionMutation,
  useGetMyTradeTxByProjectQuery,
} = transactionApi;
