import { Coin } from '@constants/CCoin';
import { z } from 'zod';

export const createOfferZod = z.object({
  offeredPrice: z.number(),
  offerCoin: z.nativeEnum(Coin),
  offeredTokenPrice: z.number(),
  listingId: z.string(),
});

export type CreateOfferDTO = z.infer<typeof createOfferZod>;
