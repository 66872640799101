import { ReactNode } from 'react';
import { Button, ButtonProps, Box, styled } from '@mui/material';
import customColors from '@theme/customColors';
import { toRem } from '@utils/functions';
import { useTheme } from '@mui/material/styles';

interface CustomButtonProps extends ButtonProps {
  children: ReactNode;
}

const buttonsGeneralStyle = {
  borderRadius: 0,
  padding: '12px 0px 11px',
  transition: 'background 300ms ease',
  background: 'transparent',
  fontSize: '16px',
  '& + button': {
    borderLeft: `1px solid ${customColors.borderGray}`,
  },
  '&.Mui-disabled': {
    textShadow: 'none',
  },
};

export const SellButton = styled(Button)(({ theme }) => ({
  ...buttonsGeneralStyle,
  color: theme.palette.error.main,
  textShadow: `0 0 8px ${customColors.shadowRed}`,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    height: '100%',
    // eslint-disable-next-line max-len
    transition: `top ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    background: customColors.redGradient,
  },
  ':hover': {
    '&:before': {
      top: '0',
    },
  },
}));

export const BuyButton = styled(Button)(({ theme }) => ({
  ...buttonsGeneralStyle,
  color: customColors.limeGreen,
  textShadow: `0 0 8px ${customColors.shadowBlue}`,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    height: '100%',
    // eslint-disable-next-line max-len
    transition: `top ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    background: customColors.darkGreenGradient,
  },
  ':hover': {
    '&:before': {
      top: '0',
    },
  },
}));

export const ClaimButton = styled(Button)(({ theme }) => ({
  ...buttonsGeneralStyle,
  color: customColors.lightDarkOrange,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '0',
    right: '0',
    height: '100%',
    // eslint-disable-next-line max-len
    transition: `top ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
    background: customColors.darkOrangeGradient,
  },
  ':hover': {
    '&:before': {
      top: '0',
    },
  },
}));

export const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.light,
  color: theme.palette.error.main,
  ':hover': {
    backgroundColor: theme.palette.primary.contrastText,
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

export const TabBtn = styled(Button)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.text.secondary,
  padding: `0 ${toRem(12)}`,
  height: '100%',
  borderRight: '1px solid transparent',
  borderRadius: '4px',
  ':hover': {
    background: customColors.darkGray,
    opacity: 1,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const activeTabStyle = {
  backgroundColor: customColors.sidebarCollapseButtonColor,
  color: customColors.white,
};

export function ButtonGradientDark({
  children,
  sx: propStyles = {},
  ...props
}: CustomButtonProps) {
  const theme = useTheme();

  return (
    <Button
      sx={{
        textTransform: 'unset',
        borderRadius: '4px',
        overflow: 'hidden',
        width: '100%',
        fontWeight: 400,
        padding: `${toRem(11)} ${toRem(16)}`,
        color: theme.palette.text.primary,

        '&:before': {
          position: 'absolute',
          content: '""',
          top: '1px',
          bottom: '1px',
          right: '1px',
          left: '1px',
          zIndex: '1',
          opacity: '0',
          filter: 'brightness(1)',
          borderRadius: '4px',
          // eslint-disable-next-line max-len
          transition: `filter ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}, opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
          // eslint-disable-next-line max-len
          backgroundImage: `radial-gradient(circle at 25.69%, ${customColors.riverStyxDarkBlue}f5 0%, ${customColors.baliHai}D9 100%)`,
        },

        '&:after': {
          opacity: '0',
          position: 'absolute',
          content: '""',
          inset: '0',
          zIndex: '0',
          paddingTop: '100%',
          top: '50%',
          transform: 'translateY(-50%) rotateZ(0deg)',
          // eslint-disable-next-line max-len
          transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}, opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut}`,
          // eslint-disable-next-line max-len
          backgroundImage: `linear-gradient(180deg, ${customColors.midnightViolet}00 27%, ${customColors.midnightViolet}8F 47%, ${customColors.midnightViolet}00 57%, ${customColors.midnightViolet}00 74%)`,
        },

        '&.MuiButton-outlined': {
          borderColor: customColors.borderGray,
          color: theme.palette.text.secondary,

          '&:before': {
            top: '0',
            bottom: '0',
            right: '0',
            left: '0',
          },

          '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,

            '&:before': {
              opacity: '1',
            },
          },
        },
        '&.MuiButton-contained': {
          position: 'relative',
          borderColor: 'transparent',
          backgroundColor: 'transparent',

          '&:after, &:before': {
            opacity: '1',
          },

          ':hover': {
            boxShadow: 'none',
            backgroundColor: 'transparent',

            '&:after': {
              transform: 'translateY(-50%) rotateZ(180deg)',
            },

            '&:before': {
              filter: 'brightness(0.25)',
            },
          },

          '&.Mui-disabled': {
            color: theme.palette.text.secondary,
            backgroundColor: 'transparent',

            '&:after': {
              transform: 'translateY(-50%) rotateZ(180deg)',
            },

            '&:before': {
              filter: 'brightness(0.25)',
            },
          },
        },
        ...propStyles,
      }}
      variant="contained"
      {...props}
    >
      <Box position="relative" zIndex="2" display="flex">
        {children}
      </Box>
    </Button>
  );
}

export function SellGradientDark({
  children,
  sx: propStyles = {},
  ...props
}: CustomButtonProps) {
  return (
    <ButtonGradientDark
      disableRipple
      fullWidth
      sx={{ color: 'error.main', ...propStyles }}
      {...props}
    >
      {children}
    </ButtonGradientDark>
  );
}

export function BuyGradientDark({
  children,
  sx: propStyles = {},
  ...props
}: CustomButtonProps) {
  return (
    <ButtonGradientDark
      disableRipple
      fullWidth
      sx={{ color: 'success.main', ...propStyles }}
      {...props}
    >
      {children}
    </ButtonGradientDark>
  );
}
