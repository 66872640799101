import { Coin } from '@constants/CCoin';
import { z } from 'zod';

export const userBalanceZod = z.object({
  name: z.nativeEnum(Coin),
  balance: z.number(),
});

// eslint-disable-next-line import/no-unused-modules
export type UserBalance = z.infer<typeof userBalanceZod>;
