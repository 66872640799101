import { z } from 'zod';
import Moralis from 'moralis-v1';

export const updateUserZod = z.object({
  id: z.string(),
  username: z.string().optional(),
  profileImage: z.custom<Moralis.File>().optional().nullable(),
});

export type UpdateUserDTO = z.infer<typeof updateUserZod>;
