import { Coin } from '@constants/CCoin';
import { z } from 'zod';

export const createTransactionInvestmentZod = z.object({
  feeInPercentage: z.number(),
  project: z.string(),
  totalTokens: z.number(),
  investmentAmount: z.number(),
  transactionHash: z.string(),
  investmentAmountWithFee: z.number(),
  coin: z.nativeEnum(Coin),
  acceptTerms: z.boolean(),
});

export type CreateTransactionInvestmentDTO = z.infer<
  typeof createTransactionInvestmentZod
>;
