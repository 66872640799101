import { Box, Typography, Container, useTheme } from '@mui/material';
import { Svgs } from '@utils/svgs';
import { flexCentered } from '@styles/shared/SCss';
import { toRem } from '@utils/functions';
import { NO_PAGE_MS } from '@constants/CDefaultMessages';

export interface DefaultComProps {
  text?: string;
  fullHeight?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

function Default({
  text = NO_PAGE_MS,
  fullHeight,
  icon: Icon = Svgs.EmptyProjects,
}: DefaultComProps) {
  const theme = useTheme();
  const renderedSx = fullHeight
    ? { backgroundColor: 'transparent', height: '100%' }
    : {
        border: `1px solid ${theme.palette.secondary.light}80`,
        borderRadius: toRem(8),
        backgroundColor: theme.palette.background.default,
      };

  return (
    <Container
      sx={{
        '&.MuiContainer-maxWidth2lg': {
          height: fullHeight ? '100%' : 'auto',
          paddingLeft: fullHeight ? toRem(24) : '0',
          paddingRight: fullHeight ? toRem(24) : '0',
        },
      }}
      maxWidth="2lg"
    >
      <Box
        sx={{
          ...flexCentered,
          flexDirection: 'column',
          padding: toRem(47),
          ...renderedSx,
        }}
      >
        <Box
          sx={{
            marginBottom: toRem(24),
          }}
        >
          <Icon />
        </Box>
        <Container maxWidth="sm">
          <Typography
            sx={{
              ...theme.typography.h2,
              fontWeight: '400',
              lineHeight: '1.5',
              color: theme.palette.text.secondary,
              textAlign: 'center',

              [theme.breakpoints.down('sm')]: {
                fontSize: '17px',
              },
            }}
          >
            {text}
          </Typography>
        </Container>
      </Box>
    </Container>
  );
}

export default Default;
