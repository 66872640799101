import { ReactComponent as ArrowLeft } from '@svgs/arrow-left.svg';
import { ReactComponent as IconTransactions } from '@svgs/icon-transactions.svg';
import { ReactComponent as ArrowRight } from '@svgs/arrow-right.svg';
import { ReactComponent as ArrowRightAltRounded } from '@svgs/arrow-right-alt-rounded.svg';
import { ReactComponent as EmptyProjects } from '@svgs/empty-projects.svg';
import { ReactComponent as Avalanche } from '@svgs/avalanche.svg';
import { ReactComponent as Account } from '@svgs/account.svg';
import { ReactComponent as BigLeftArrow } from '@svgs/big-left-arrow.svg';
import { ReactComponent as BNB } from '@svgs/bnb.svg';
import { ReactComponent as BUSD } from '@svgs/busd.svg';
import { ReactComponent as Calendar } from '@svgs/calendar.svg';
import { ReactComponent as CardsView } from '@svgs/cards-view.svg';
import { ReactComponent as Close } from '@svgs/close.svg';
import { ReactComponent as Copy } from '@svgs/copy.svg';
import { ReactComponent as CurrencyExchangeFilled } from '@svgs/currency-exchange-filled.svg';
import { ReactComponent as DAI } from '@svgs/dai.svg';
import { ReactComponent as Dashboard } from '@svgs/dashboard.svg';
import { ReactComponent as Discord } from '@svgs/discord.svg';
import { ReactComponent as HelpAndSupport } from '@svgs/help-and-support.svg';
import { ReactComponent as Dollar } from '@svgs/dollar.svg';
import { ReactComponent as Enter } from '@svgs/enter.svg';
import { ReactComponent as ETH } from '@svgs/eth.svg';
import { ReactComponent as Explore } from '@svgs/explore.svg';
import { ReactComponent as Filter } from '@svgs/filter.svg';
import { ReactComponent as Info } from '@svgs/info.svg';
import { ReactComponent as Internet } from '@svgs/internet.svg';
import { ReactComponent as LockedMarker } from '@svgs/locked-marker.svg';
import { ReactComponent as Logo } from '@svgs/logo.svg';
import { ReactComponent as Metamask } from '@svgs/metamask.svg';
import { ReactComponent as Notification } from '@svgs/notification.svg';
import { ReactComponent as Projects } from '@svgs/projects.svg';
import { ReactComponent as Polygon } from '@svgs/polygon.svg';
import { ReactComponent as RoadmapActive } from '@svgs/roadmap-active.svg';
import { ReactComponent as RoadmapStepper } from '@svgs/roadmap-stepper.svg';
import { ReactComponent as RoadmapStepperActive } from '@svgs/roadmap-stepper-active.svg';
import { ReactComponent as Roi } from '@svgs/roi.svg';
import { ReactComponent as Search } from '@svgs/search.svg';
import { ReactComponent as Settings } from '@svgs/settings.svg';
import { ReactComponent as SignIn } from '@svgs/signIn.svg';
import { ReactComponent as SmallArrowDown } from '@svgs/small-arrow-down.svg';
import { ReactComponent as SmallArrowUp } from '@svgs/small-arrow-up.svg';
import { ReactComponent as TableView } from '@svgs/table-view.svg';
import { ReactComponent as Kyc } from '@svgs/kyc.svg';
import { ReactComponent as TotalInvestments } from '@svgs/total-investments.svg';
import { ReactComponent as ActivitySteam } from '@svgs/activity-stream.svg';
import { ReactComponent as UnlockedMarker } from '@svgs/unlocked-marker.svg';
import { ReactComponent as USDC } from '@svgs/usdc.svg';
import { ReactComponent as USDT } from '@svgs/usdt.svg';
import { ReactComponent as WalletConnect } from '@svgs/wallet-connect.svg';
import { ReactComponent as Portfolio } from '@svgs/portfolio.svg';
import { ReactComponent as Warning } from '@svgs/warning.svg';
import { ReactComponent as MyListings } from '@svgs/my-listings.svg';
import { ReactComponent as Offers } from '@svgs/offers.svg';
import { ReactComponent as Success } from '@svgs/success.svg';
import { ReactComponent as Error } from '@svgs/error.svg';
import { ReactComponent as TelegramIcon } from '@svgs/telegram-icon.svg';
import { ReactComponent as DiscordIcon } from '@svgs/discord-icon.svg';

export const Svgs = {
  ArrowLeft,
  IconTransactions,
  ArrowRight,
  ArrowRightAltRounded,
  Avalanche,
  Account,
  BigLeftArrow,
  BNB,
  BUSD,
  Calendar,
  CardsView,
  Close,
  Copy,
  CurrencyExchangeFilled,
  DAI,
  Dashboard,
  Discord,
  HelpAndSupport,
  Dollar,
  EmptyProjects,
  Enter,
  ETH,
  Explore,
  Filter,
  Info,
  Internet,
  LockedMarker,
  Logo,
  Metamask,
  Notification,
  Projects,
  Polygon,
  RoadmapActive,
  RoadmapStepper,
  RoadmapStepperActive,
  Roi,
  Search,
  Settings,
  SignIn,
  SmallArrowDown,
  SmallArrowUp,
  TableView,
  Kyc,
  TotalInvestments,
  ActivitySteam,
  UnlockedMarker,
  USDC,
  USDT,
  WalletConnect,
  Portfolio,
  Warning,
  MyListings,
  Offers,
  Success,
  Error,
  TelegramIcon,
  DiscordIcon,
};
