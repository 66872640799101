/* eslint-disable import/no-unused-modules */
import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import Moralis from 'moralis-v1';
import { ethInvestmentZod } from '@typescript/models/EthInvestment.model';
import {
  PaginationDTO,
  resourceWithPaginatedZod,
} from '@typescript/dtos/shared/pagination.dto';
import { getEligibilityZod } from '@typescript/dtos/shared/tiers.dto';
import { userApi } from './userApi';
import {
  CreateEthInvestmentDto,
  CheckEthInvestmentDto,
} from '../../typescript/dtos/eth-investment/createEthInvestment.dto';
import { ethAllocationApi } from './ethAllocationApi';

export const ethInvestmentApi = createApi({
  reducerPath: ApiSlice.ETH_INVESTMENT,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.ETH_INVESTMENTS],
  endpoints: (builder) => ({
    createEthInvestment: builder.mutation({
      async queryFn(dto: CreateEthInvestmentDto) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_ETH_INVESTMENT,
            dto,
          );
          return { data: ethInvestmentZod.parse(response) };
        } catch (error) {
          throw Error(`createEthInvestment: ${error}`);
        }
      },
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(userApi.util.invalidateTags([ApiTag.USER]));
        });
      },
    }),
    confirmEthInvestment: builder.mutation({
      async queryFn(hash: string) {
        try {
          await Moralis.Cloud.run(CloudFunctions.CONFIRM_ETH_INVESTMENT, {
            hash,
          });
          return { data: null };
        } catch (error) {
          throw Error(`confirmEthInvestment Error: ${error}`);
        }
      },
      onQueryStarted: (_, api) => {
        api.dispatch(userApi.util.invalidateTags([ApiTag.USER]));
        api.dispatch(
          ethAllocationApi.util.invalidateTags([ApiTag.ETH_ALLOCATIONS]),
        );
      },
      invalidatesTags: [ApiTag.ETH_INVESTMENTS],
    }),
    checkEthInvestment: builder.query({
      async queryFn(payload: CheckEthInvestmentDto) {
        try {
          await Moralis.Cloud.run(CloudFunctions.CHECK_ETH_INVESTMENT, payload);
          return { data: null };
        } catch (error) {
          throw Error(`checkEthInvestment: ${error}`);
        }
      },
      providesTags: [ApiTag.ETH_INVESTMENTS],
    }),
    getEthInvestments: builder.query({
      async queryFn(payload: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ETH_INVESTMENT,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(ethInvestmentZod).parse(response),
          };
        } catch (error) {
          throw Error(`checkEthInvestment: ${error}`);
        }
      },
      providesTags: [ApiTag.ETH_INVESTMENTS],
    }),
    getEligibleInvestment: builder.query({
      async queryFn(payload: { projectId: string }) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ELIGIBLE_INVESTMENT,
            payload,
          );
          return { data: getEligibilityZod.parse(response) };
        } catch (error) {
          throw Error(`getEligibleInvestment: ${error}`);
        }
      },
      providesTags: [ApiTag.ETH_INVESTMENTS],
    }),
  }),
});

export const {
  useCreateEthInvestmentMutation,
  useConfirmEthInvestmentMutation,
  useLazyCheckEthInvestmentQuery,
  useGetEthInvestmentsQuery,
  useGetEligibleInvestmentQuery,
  useLazyGetEligibleInvestmentQuery,
} = ethInvestmentApi;
