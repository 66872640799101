import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Formik,
  FormikHelpers,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { TAllocation } from '@typescript/models/Allocation.model';
import useListings from '@hooks/useListings';
import { MainBoxMargin } from '@styles/shared/SCss';
import { WTSListingInitialValues } from '@formik/modal/ModalInitialData';
import { WTSListingValidationSchema } from '@formik/modal/ModalValidation';
import SummaryHeader from '@components/shared/SummaryHeader';
import DashboardPortfolioTable from '@components/dashboard/DashboardPortfolioTable';
import DashboardPortfolioResponsive from '@components/dashboard/responsive/DashboardPortfolioResponsive';
import DashboardTransactionsResponsive from '@components/dashboard/responsive/DashboardTransactionsResponsive';
import { TCreateWTSListingInitialValues } from '@typescript/TModalFormik';
import WtsStep1Modal from '@components/modals/listings/WtsStep1Modal';
import WtsStep2Modal from '@components/modals/listings/WtsStep2Modal';
import { Day } from '@typescript/models/Listing.model';
import DashboardTransactionsTable from '@components/dashboard/DashboardTransactionsTable';
import { createListingZod } from '@typescript/dtos/listing/createListing.dto';
import { useGetMyAllocationsQuery } from '@store/api/allocationApi';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import useNotification from '@hooks/useNotification';
import DashboardInactiveNotification from '@components/dashboard/DashboardInactiveNotification';
import DashboardSocialReminder from '@components/dashboard/DashboardSocialReminder';
import { toRem } from '@utils/functions';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import pluralize from 'pluralize';
import { useGetMyInactiveOffersQuery } from '@store/api/offerApi';

function Dashboard() {
  const {
    data: { resource: myAllocations } = { resource: [], count: 0 },
    isLoading: isLoadingAllocations,
    isFetching: isFetchingMyAllocations,
  } = useGetMyAllocationsQuery(paginationZod.parse({ page: 1, pageSize: 5 }));

  const {
    data: { count: inactiveOffersCount } = {
      count: 0,
    },
  } = useGetMyInactiveOffersQuery(
    paginationZod.parse({ page: 1, pageSize: 1 }),
  );

  const theme = useTheme();

  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  const { notifyError, notifySuccess } = useNotification();

  const [isOfferNotificationRead, setIsOfferNotificationRead] =
    useState<boolean>(
      Boolean(localStorage.getItem('offerNotificationRead')) ||
        inactiveOffersCount === 0,
    );

  const { createWTS } = useListings();

  const [steps, setSteps] = useState<1 | 2>(1);

  const [openStep1Modal, setOpenStep1Modal] = useState<boolean>(false);

  const [openStep2Modal, setOpenStep2Modal] = useState<boolean>(false);

  const [currentAllocation, setCurrentAllocation] =
    useState<TAllocation | null>();

  const handleSellNowBtn = useCallback((allocation: TAllocation) => {
    setCurrentAllocation(allocation);
    setOpenStep1Modal(true);
    setSteps(1);
  }, []);

  const changeSteps = (number: 1 | 2) => {
    setSteps(number);
  };

  const handleOnSubmitFormik = async (
    values: TCreateWTSListingInitialValues,
    props: FormikHelpers<TCreateWTSListingInitialValues>,
  ) => {
    try {
      if (currentAllocation) {
        await createWTS(
          createListingZod.parse({
            amount: +values.tokenAmount,
            coins: values.coins,
            negotiable: values.negotiable,
            price: +values.totalCost,
            tokenPrice: +values.tokenPrice,
            projectId: currentAllocation.project.id,
            expireIn: +values.expireIn as Day,
            discordName: values.discordName,
            telegramLink: values.telegramLink,
          }),
        ).unwrap();
        notifySuccess('Listing created!');
        setOpenStep1Modal(false);
        setOpenStep2Modal(false);
        setSteps(1);
        // eslint-disable-next-line react/prop-types
        props.resetForm();
      }
    } catch (error) {
      setOpenStep1Modal(false);
      setOpenStep2Modal(false);
      setSteps(1);
      // eslint-disable-next-line react/prop-types
      props.resetForm();
      notifyError('Listing creation failed!');
      throw error;
    }
  };

  const handleDiscardOfferNotification = () => {
    localStorage.setItem('offerNotificationRead', 'true');
    setIsOfferNotificationRead(true);
  };

  useEffect(() => {
    setIsOfferNotificationRead(
      Boolean(localStorage.getItem('offerNotificationRead')) ||
        inactiveOffersCount === 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactiveOffersCount]);

  return (
    <Box
      sx={{
        marginTop: { xs: '67px', lg: MainBoxMargin },
        marginBottom: MainBoxMargin,
      }}
    >
      <Box>
        <SummaryHeader />
        <Box
          sx={{
            maxWidth: '1200px',
            margin: '0 auto',
            pb: '56px',
          }}
        >
          <DashboardSocialReminder />

          <DashboardInactiveNotification />
          <Box
            sx={{
              transition: `min-height ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
            }}
          >
            {inactiveOffersCount !== 0 && !isOfferNotificationRead && (
              <Box
                sx={{
                  padding: toRem(12),
                  backgroundColor: 'warning.main',
                  borderRadius: toRem(8),
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'ceter',
                  fontSize: toRem(16),

                  [theme.breakpoints.down('md')]: {
                    flexDirection: 'column',
                    gap: toRem(14),
                  },
                }}
                mt={3}
                mx={2}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: theme.palette.background.default,
                  }}
                >
                  <Typography>
                    You have{' '}
                    {inactiveOffersCount === 99 ? '99+' : inactiveOffersCount}{' '}
                    inactive {pluralize('offers', inactiveOffersCount)}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      justifyContent: 'space-between',
                    },
                    [theme.breakpoints.up('md')]: {
                      display: 'flex',
                      flexShrink: 0,
                      alignItems: 'center',
                    },
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: 'transparent',
                      color: theme.palette.background.default,
                      flexShrink: '0',
                      '&:hover': {
                        color: theme.palette.text.primary,
                      },
                    }}
                    onClick={handleDiscardOfferNotification}
                    size="small"
                    variant="outlined"
                  >
                    Discard
                    <CloseIcon fontSize="small" />
                  </Button>

                  <Link to="/offers/1">
                    <Button
                      LinkComponent="span"
                      sx={{ marginLeft: { xs: '0', md: toRem(8) } }}
                      size="small"
                      variant="outlined"
                      href="/offers/1"
                    >
                      My Inactive Offers{' '}
                      <ArrowForwardIcon
                        sx={{ marginLeft: toRem(4) }}
                        fontSize="small"
                      />
                    </Button>
                  </Link>
                </Box>
              </Box>
            )}
          </Box>

          <Box mx={2}>
            <Typography
              variant="h6"
              sx={{
                mt: '1.5rem',
                mb: '1rem',
                fontSize: { xs: '15px', lg: '13px' },
              }}
            >
              Portfolio
            </Typography>
            {downLg ? (
              <DashboardPortfolioResponsive
                myAllocations={myAllocations}
                handleSellNowBtn={handleSellNowBtn}
                isFetchingMyAllocations={isLoadingAllocations}
              />
            ) : (
              <DashboardPortfolioTable
                handleSellNowBtn={handleSellNowBtn}
                myAllocations={myAllocations}
                isLoading={isLoadingAllocations}
                isFetching={isFetchingMyAllocations}
              />
            )}
            <Typography
              variant="h6"
              sx={{
                mt: '1.5rem',
                mb: '1rem',
                fontSize: { xs: '15px', lg: '13px' },
              }}
            >
              Transactions
            </Typography>
            {downLg ? (
              <DashboardTransactionsResponsive />
            ) : (
              <DashboardTransactionsTable />
            )}
          </Box>
        </Box>
      </Box>
      {currentAllocation && (
        <Formik
          initialValues={WTSListingInitialValues}
          validate={(values) => {
            try {
              validateYupSchema(
                values,
                WTSListingValidationSchema(
                  currentAllocation.project.currentPrice,
                  currentAllocation.lockedTokens,
                ),
                true,
                values,
              );
            } catch (err) {
              return yupToFormErrors(err);
            }

            return {};
          }}
          onSubmit={(values, props) => {
            handleOnSubmitFormik(values, props);
          }}
        >
          <>
            {steps === 1 && openStep1Modal && currentAllocation && (
              <WtsStep1Modal
                openModal={openStep1Modal}
                closeModal={() => setOpenStep1Modal(false)}
                changeSteps={changeSteps}
                openWtsStep2Modal={() => setOpenStep2Modal(true)}
                project={currentAllocation.project}
              />
            )}
            {steps === 2 && openStep2Modal && currentAllocation && (
              <WtsStep2Modal
                openModal={openStep2Modal}
                closeModal={() => setOpenStep2Modal(false)}
                openWtsStep1Modal={() => setOpenStep1Modal(true)}
                changeSteps={changeSteps}
                project={currentAllocation.project}
              />
            )}
          </>
        </Formik>
      )}
    </Box>
  );
}

export default Dashboard;
