import { BreakpointsOptions } from '@mui/material';

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 425,
    xsm: 575,
    md: 768,
    lg: 1024,
    '2lg': 1300,
    xl: 1600,
    '2xl': 2100,
  },
};

export default breakpoints;
