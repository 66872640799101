import { Box, Select, styled, TextField } from '@mui/material';
import colors from '@theme/customColors';

export const SearchBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: colors.riverStyxDarkBlue,
  maxHeight: '36px',
  alignItems: 'center',
  borderRadius: '6px',
  [theme.breakpoints.down('lg')]: {
    maxHeight: 'unset',
    padding: '2px 0',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const SearchSelect = styled(Select)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.secondary.light}`,
  borderTopLeftRadius: '0',
  borderBottomLeftRadius: '0',
  backgroundColor: 'transparent',
  color: theme.palette.text.secondary,
  fontSize: 13,
  '& .MuiSvgIcon-root': {
    marginTop: '2px',
    width: '20px',
    height: '20px',
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down('lg')]: {
    borderLeft: 'none',
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    backgroundColor: colors.riverStyxDarkBlue,
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
}));

export const SearchBar = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent !important',
    fontSize: 13,
  },
  '& .MuiInputBase-input': {
    width: '310px !important',
    [theme.breakpoints.down('lg')]: {
      width: '110px !important',
    },
    color: colors.boxColor,
    padding: '0.5rem !important',
    '&::placeholder': {
      color: theme.palette.text.secondary,
      opacity: 1,
    },
  },
}));
