import { useState } from 'react';
import { useParams } from 'react-router-dom';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import DefaultPage from '@pages/Default';
import { MainBox } from '@styles/shared/SBoxes';
import HeaderEdit from '@components/project/project-edit/ProjectEditHeader';
import ValuesEdit from '@components/project/project-edit/ProjectEditValues';
import ProjectHeader from '@components/project/project-page/ProjectHeader';
import ProjectValues from '@components/project/project-page/ProjectValues';
import ProjectDetails from '@components/project/project-page/ProjectDetails';
import ProjectListingsTable from '@components/project/project-page/ProjectListingsTable';
import { NO_PROJECT_NAME_MS } from '@constants/CDefaultMessages';
import { ProjectStatus } from '@constants/CProject';
import { Box, useTheme } from '@mui/material';
import { useGetProjectBySlugQuery } from '@store/api/projectApi';
import ProjectTransactionsTable from '@components/project/project-page/ProjectTransactionsTable';
import { TEditSection } from '@typescript/models/Project.model';

function ProjectPage() {
  const theme = useTheme();
  const { id: projectSlug } = useParams();

  const { data: currentProject, isLoading } = useGetProjectBySlugQuery({
    name: projectSlug || '',
  });

  const [editSection, setEditSection] = useState<TEditSection>({
    header: false,
    values: false,
  });

  const changeEditSection = (value: boolean, section: keyof TEditSection) => {
    setEditSection((prev) => ({ ...prev, [section]: value }));
  };

  if (isLoading) return <IsLoadingCom />;

  if (!currentProject) {
    return <DefaultPage text={NO_PROJECT_NAME_MS} />;
  }

  return (
    <MainBox
      sx={{
        height: 'unset',
        maxHeight: 'unset!important',
        overflow: 'unset',
        p: { xs: 0, md: '15px' },
        pb: { xs: 14, md: 7, lg: 0 },
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('lg')]: {
          maxWidth: 'calc(100vw - 200px)',
        },
      }}
    >
      {currentProject && (
        <Box>
          {editSection.header ? (
            <HeaderEdit
              project={currentProject}
              onClose={() => changeEditSection(false, 'header')}
            />
          ) : (
            <ProjectHeader
              project={currentProject}
              changeEditSection={changeEditSection}
            />
          )}
          {editSection.values ? (
            <ValuesEdit
              project={currentProject}
              onClose={() => changeEditSection(false, 'values')}
            />
          ) : (
            <ProjectValues
              project={currentProject}
              changeEditSection={changeEditSection}
            />
          )}

          <ProjectTransactionsTable project={currentProject} />

          {currentProject.status !== ProjectStatus.LIVE &&
            currentProject.status !== ProjectStatus.LIVE_PRIVATE && (
              <ProjectListingsTable project={currentProject} />
            )}
          <ProjectDetails project={currentProject} />
        </Box>
      )}
    </MainBox>
  );
}

export default ProjectPage;
