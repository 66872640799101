import { z } from 'zod';
import { userZod } from './User.model';
import { ethProjectZod } from './EthProject.model';

export const ethAllocationZod = z.object({
  id: z.string(),
  user: userZod,
  ethProject: ethProjectZod,
  investment: z.number(),
  percentage: z.number(),
  social: z.string().optional(),
});

export type TEthAllocation = z.infer<typeof ethAllocationZod>;
