import { z } from 'zod';
import { ProjectStatus } from '@constants/CProject';
import { feeZod } from './Fee.model';

export const partnersCardZod = z.object({
  id: z.string(),
  imageUrl: z.string(),
});

export const teamCardZod = z.object({
  id: z.string(),
  imageUrl: z.string(),
  name: z.string(),
  occupation: z.string(),
  description: z.string(),
});

export const socialCardZod = z.object({
  id: z.string(),
  url: z.string(),
  imageUrl: z.string(),
});

export const projectDetailsZod = z.object({
  id: z.string(),
  overviewText: z.string(),
  partnersCards: z.array(partnersCardZod),
  teamCards: z.array(teamCardZod),
  socialCards: z.array(socialCardZod),
});

export const projectZod = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string(),
  image: z.string(),
  status: z.number(),
  totalLockedTokens: z.number(),
  totalUnlockedTokens: z.number(),
  totalLockedValue: z.number(),
  totalUnlockedValue: z.number(),
  chain: z.string().optional(),
  networkImage: z.string(),
  round: z.string(),
  tokenPrice: z.number(),
  currentPrice: z.number(),
  tokenAddress: z.string().optional(),
  projectMaxLockedTokens: z.number(),
  fundraisingTarget: z.number(),
  minInvestment: z.number(),
  maxInvestment: z.number(),
  investmentAmount: z.number(),
  investmentAmountWithFee: z.number(),
  totalTokens: z.number(),
  vestingDetails: z.string(),
  symbol: z.string().optional(),
  hidden: z.boolean(),
  performance: z.number(),
  merkleRoot: z.string().optional(),
  details: projectDetailsZod,
  fee: feeZod,
  userLockedTokensOnProject: z.number(),
  countWTS: z.number(),
  countWTB: z.number(),
  isEthProject: z.boolean(),
});

export type TProject = z.infer<typeof projectZod>;

export interface TProjectCreateInitialValues {
  image: string;
  name: string;
  symbol: string;
  round: string;
  tokenPrice: string;
  fundraisingTarget: string;
  maxInvestment: string;
  feeInPercentagePrivate: string;
  minInvestment: string;
  feeInPercentage: string;
  lvWallet: string;
  status: ProjectStatus;
  vestingDetails: string;
  totalTokens: number;
  networkImage: string;
  chain: string;
  isLegionProject: boolean;
}

export interface TEditSection {
  header: boolean;
  values: boolean;
}

export interface TEditProjectDetails {
  overview: boolean;
  teams: boolean;
  partners: boolean;
  social: boolean;
}
