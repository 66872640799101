import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import {
  resourceWithPaginatedZod,
  PaginationDTO,
} from '@typescript/dtos/shared/pagination.dto';
import { IdWithPaginationDTO } from '@typescript/dtos/shared/id.dto';
import { CreateTransactionInvestmentDTO } from '@typescript/dtos/transactionInvestment/createTransactionInvestment.dto';
import { transactionInvestmentZod } from '@typescript/models/InvestmentTransaction.model';
import Moralis from 'moralis-v1';
import { CheckTxInvestmentDTO } from '@typescript/dtos/transactionInvestment/getTxInvestment.dto';
import { userApi } from './userApi';

export const transactionInvestmentApi = createApi({
  reducerPath: ApiSlice.TRANSACTIONS_INVESTMENT,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.TRANSACTIONS_INVESTMENT],
  endpoints: (builder) => ({
    getTransactionInvestment: builder.query({
      async queryFn(payload: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ALL_TRANSACTION_INVESTMENT,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(transactionInvestmentZod).parse(
              response,
            ),
          };
        } catch (error) {
          throw Error(`getTransactionInvestment: ${error}`);
        }
      },
      providesTags: [ApiTag.TRANSACTIONS_INVESTMENT],
    }),
    getTransactionInvestmentByProjectId: builder.query({
      async queryFn(payload: IdWithPaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ALL_TRANSACTION_INVESTMENT_PROJECT,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(transactionInvestmentZod).parse(
              response,
            ),
          };
        } catch (error) {
          throw Error(`getTransactionInvestmentByProjectId: ${error}`);
        }
      },
      providesTags: [ApiTag.TRANSACTIONS_INVESTMENT],
    }),
    getMyTransactionInvestment: builder.query({
      async queryFn(payload: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_INVESTMENT_TX,
            payload,
          );

          return {
            data: resourceWithPaginatedZod(transactionInvestmentZod).parse(
              response,
            ),
          };
        } catch (error) {
          throw Error(`getMyTransactionInvestment: ${error}`);
        }
      },
      providesTags: [ApiTag.TRANSACTIONS_INVESTMENT],
    }),
    createTransactionInvestment: builder.mutation({
      async queryFn(dto: CreateTransactionInvestmentDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_TRANSACTION_INVESTMENT,
            dto,
          );
          return { data: transactionInvestmentZod.parse(response) };
        } catch (error) {
          throw Error(`createTransactionInvestment: ${error}`);
        }
      },
      onQueryStarted: (_, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(userApi.util.invalidateTags([ApiTag.USER]));
        });
      },
    }),
    confirmInvestmentTransaction: builder.mutation({
      async queryFn(hash: string) {
        try {
          await Moralis.Cloud.run(
            CloudFunctions.CONFIRM_INVESTMENT_TRANSACTION,
            { hash },
          );
          return { data: null };
        } catch (error) {
          throw Error(`confirmInvestmentTransaction Error: ${error}`);
        }
      },
      onQueryStarted: (_, api) => {
        api.dispatch(userApi.util.invalidateTags([ApiTag.USER]));
      },
      invalidatesTags: [ApiTag.TRANSACTIONS_INVESTMENT],
    }),
    checkTxInvestment: builder.query({
      async queryFn(payload: CheckTxInvestmentDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CHECK_TX_INVESTMENT,
            payload,
          );
          return {
            data: response,
          };
        } catch (error) {
          throw Error(`getTransactionInvestmentByProjectId: ${error}`);
        }
      },
      providesTags: [ApiTag.TRANSACTIONS_INVESTMENT],
    }),
  }),
});

export const {
  useGetTransactionInvestmentByProjectIdQuery,
  useGetMyTransactionInvestmentQuery,
  useCreateTransactionInvestmentMutation,
  useLazyCheckTxInvestmentQuery,
  useConfirmInvestmentTransactionMutation,
} = transactionInvestmentApi;
