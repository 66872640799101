import { z } from 'zod';

export const createFeeZod = z.object({
  lvWallet: z.string(),
  receiverWallet: z.string(),
  feeInUsd: z.number(),
  feeInTokens: z.number(),
  feeInPercentage: z.number(),
  feeInPercentagePrivate: z.number(),
});

export type CreateFeeDTO = z.infer<typeof createFeeZod>;
