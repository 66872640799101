import Decimal from 'decimal.js';
import { Box, SxProps, Typography } from '@mui/material';
import numeral from 'numeral';
import { DECIMAL_LIMIT } from '@constants/CLimits';

type Operation = '+' | '-' | '*' | '/' | '%' | '%?' | '>%<';
type Decimals = 2 | 0;

export enum ServerNetwork {
  BSC = 'bsc',
  BSC_TESTNET = 'bsc_testnet',
}
export enum ServerNetworkLabels {
  BSC = 'BSC',
  BSC_TESTNET = 'BSC Testnet',
}

export function calculate(
  nr1: number,
  operation: Operation,
  nr2: number,
): number {
  const decimalNr1 = new Decimal(nr1 || 0);
  const decimalNr2 = new Decimal(nr2 || 0);

  switch (operation) {
    case '+':
      return decimalNr1
        .plus(decimalNr2)
        .toDecimalPlaces(DECIMAL_LIMIT)
        .toNumber();
    case '-':
      return decimalNr1
        .minus(decimalNr2)
        .toDecimalPlaces(DECIMAL_LIMIT)
        .toNumber();
    case '*':
      return decimalNr1
        .times(decimalNr2)
        .toDecimalPlaces(DECIMAL_LIMIT)
        .toNumber();
    case '/':
      return decimalNr1
        .div(decimalNr2)
        .toDecimalPlaces(DECIMAL_LIMIT)
        .toNumber();
    case '%':
      // ? nr1 percentage of nr2
      return decimalNr1
        .div(100)
        .times(decimalNr2)
        .toDecimalPlaces(DECIMAL_LIMIT)
        .toNumber();
    case '%?':
      // ? nr1 is what percentage of nr2
      return decimalNr1
        .div(decimalNr2)
        .times(100)
        .toDecimalPlaces(DECIMAL_LIMIT)
        .toNumber();
    case '>%<':
      if (decimalNr2.isZero()) {
        return 0;
      }
      // ? How much is nr1 greater or less than nr2 in percentage?
      return decimalNr1
        .minus(nr2)
        .div(decimalNr2)
        .times(100)
        .toDecimalPlaces(DECIMAL_LIMIT, Decimal.ROUND_DOWN)
        .toNumber();
    default:
      throw new Error(`Invalid operation: ${operation}`);
  }
}

export const formatModalDecimals = (value: string) => {
  const numberOfDecimals = 4;

  if (+value >= 0) {
    const index = value.indexOf('.');
    if (index > -1) {
      return value.slice(0, index + numberOfDecimals + 1);
    }
    return value;
  }
  return value;
};

export const formatNr = (
  value: number,
  currency?: boolean,
  decimals?: Decimals,
) => {
  if (Number.isNaN(value) || !Number.isFinite(value)) {
    return currency ? '$0' : '0';
  }

  let formatter = '0,0.[0000]';
  if (decimals === 0) {
    formatter = '0,0';
  }
  if (decimals === 2) {
    formatter = '0,0.[00]';
  }

  if (currency) {
    formatter = `$${formatter}`;
  }

  const formattedNumber = numeral(value).format(formatter);

  return formattedNumber;
};

export const formatToNumber = (value: number | string, decimals: 2 | 4 = 2) =>
  new Decimal(value).toDecimalPlaces(decimals).toNumber();

export const calculateProfitLoss = (
  price: number,
  askingPrice: number,
  sx?: SxProps,
) => {
  // ? He sells under the current price
  if (askingPrice < price) {
    let lossPercentage = calculate(askingPrice, '/', price);
    lossPercentage = calculate(lossPercentage, '*', 100);
    lossPercentage = calculate(100, '-', lossPercentage);

    return (
      <Typography
        sx={{
          fontSize: '16px',
          color: (theme) => theme.palette.error.main,
          ...sx,
        }}
      >
        -{formatNr(lossPercentage, undefined, 0)}%
      </Typography>
    );
  }

  const priceInMultiplication = calculate(askingPrice, '/', price);

  // ? He sells over the current price
  return (
    <Typography
      sx={{
        fontSize: '14px',
        color: (theme) => theme.palette.success.main,
        ...sx,
      }}
    >
      {formatNr(priceInMultiplication, undefined, 2)}x
    </Typography>
  );
};

export const sliceWalletAddress = (wallet: string) => {
  const slicedWallet = `${wallet?.slice(0, 4)}...${wallet?.slice(-4)}`;
  return slicedWallet;
};

export const toRem = (pixels: number) => {
  const rems = calculate(pixels, '/', 16);
  return `${rems}rem`;
};

export const formatProjectNameParams = (projectName: string) =>
  projectName.replace(/%20/g, ' ');

export const cryptoFormat = (
  number: number,
  sx?: SxProps,
  decimals?: Decimals,
) => {
  if (!number) {
    return 0;
  }
  const MAX_DECIMALS = 4;
  const nrString = number.toString();
  const [integerPart, decimalPart] = nrString.split('.');

  let consecutiveZeroes = 0;

  if (decimalPart?.length > 0) {
    const consecutiveZeroesRegex = decimalPart.match(/^0+/);
    const consecutiveZeroesString = consecutiveZeroesRegex?.[0];

    const totalNumberOfConsecutiveZeroes = consecutiveZeroesString?.length;

    consecutiveZeroes = totalNumberOfConsecutiveZeroes
      ? totalNumberOfConsecutiveZeroes - 1
      : 0;

    if (consecutiveZeroes === 0) {
      return (
        <Typography sx={{ display: 'flex', fontSize: '14px', ...sx }}>
          {formatNr(number, true, decimals || 2)}
        </Typography>
      );
    }

    const remainingDecimalPart = decimalPart.slice(consecutiveZeroes + 1);
    const remainingDecimalPartRounded = remainingDecimalPart.slice(
      0,
      MAX_DECIMALS,
    );

    return (
      <Typography sx={{ display: 'flex', fontSize: '14px', ...sx }}>
        ${integerPart}.0
        <Box
          component="sub"
          sx={{
            mt: '6px',
            paddingInline: '2px',
            fontWeight: 'bold',
            height: 'unset !important',
          }}
        >
          {consecutiveZeroes}
        </Box>
        {remainingDecimalPartRounded}
      </Typography>
    );
  }

  return (
    <Typography sx={{ display: 'flex', fontSize: '14px', ...sx }}>
      {formatNr(number, true, decimals || 2)}
    </Typography>
  );
};
export const calculateTotalValueAfterFee = (fee: number, value: number) => {
  const multiplicationFeeAndValue = calculate(fee, '*', value);
  const calculateFee = calculate(multiplicationFeeAndValue, '/', 100);
  return calculate(value, '-', calculateFee);
};

// eslint-disable-next-line import/no-unused-modules
export const calculateInvestmentAmount = ({
  investmentAmountWithFee,
  feePercentage,
}: {
  investmentAmountWithFee: number;
  feePercentage: number;
}): number => {
  // Convert fee percentage to a decimal
  const feeDecimal = calculate(feePercentage, '/', 100);

  // Calculate the percentage left after the fee is deducted
  const percentageLeft = calculate(1, '-', feeDecimal);

  // Calculate the original investment
  const investmentAmount = calculate(
    investmentAmountWithFee,
    '/',
    percentageLeft,
  );

  return investmentAmount;
};
