import { TypographyOptions } from '@mui/material/styles/createTypography';
import { toRem } from '@utils/functions';
import { CustomPalette } from '@theme/types';

export default function createTypography(
  colors: CustomPalette,
): TypographyOptions {
  return {
    fontFamily: 'Larsseit',
    fontSize: 14,
    h1: {
      fontSize: toRem(40),
      fontWeight: 500,
      color: colors.text?.primary,
    },
    h2: {
      fontSize: toRem(20),
      fontWeight: 400,
      color: colors.text?.primary,
    },
    h3: {
      fontSize: toRem(17),
      fontWeight: 400,
      color: colors.primary.main,
    },
    h4: {
      fontSize: toRem(16),
      fontWeight: 400,
      color: colors.primary.main,
    },
    h5: {
      fontSize: toRem(14),
      fontWeight: '400 !important',
      color: colors?.error?.light,
    },
    h6: {
      lineHeight: 'initial',
      fontSize: toRem(12),
      fontWeight: 500,
      color: colors.primary.main,
    },
    button: {
      fontSize: toRem(13),
      lineHeight: 'initial',
      textTransform: 'capitalize',
      fontWeight: 700,
    },
  };
}
