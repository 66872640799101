/* eslint-disable import/no-unused-modules */
import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import Moralis from 'moralis-v1';
import {
  PaginationDTO,
  resourceWithPaginatedZod,
} from '@typescript/dtos/shared/pagination.dto';
import { ethAllocationZod } from '@typescript/models/EthAllocation.model';

export const ethAllocationApi = createApi({
  reducerPath: ApiSlice.ETH_ALLOCATION,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.ETH_ALLOCATIONS],
  endpoints: (builder) => ({
    getEthAllocations: builder.query({
      async queryFn(payload: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ETH_ALLOCATIONS,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(ethAllocationZod).parse(response),
          };
        } catch (error) {
          throw Error(`getEthAllocations: ${error}`);
        }
      },
      providesTags: [ApiTag.ETH_ALLOCATIONS],
    }),
  }),
});

export const { useGetEthAllocationsQuery } = ethAllocationApi;
