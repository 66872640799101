import customColors from '@theme/customColors';

export const borderRadius6 = { borderRadius: '6px' };
export const flexColumn = { display: 'flex', flexDirection: 'column' };
export const MainBoxMargin = '56px';
export const greenTextShadow = '0px 13px 10px rgba(71, 228, 172, 0.07)';
export const redTextShadow = '0px 13px 10px rgba(71, 228, 172, 0.07)';
export const border1pxPrimaryLight = `1px solid ${customColors.borderGray}`;
export const shadedWhite = { color: customColors.shadedWhite };

export const flexCentered = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
