import { z } from 'zod';
import { Coin } from '@constants/CCoin';
import { userZod } from './User.model';
// eslint-disable-next-line import/no-cycle
import { baseListingZod, offerTradeStatusZod } from './Listing.model';

export const offerZod = z.object({
  id: z.string(),
  listing: z.lazy(() => baseListingZod),
  offerer: userZod,
  offeredPrice: z.number(),
  offeredTokenPrice: z.number(),
  status: z.number(),
  offerCoin: z.nativeEnum(Coin),
  expireAt: z.string(),
  rejectedBy: userZod.optional(),
  tradeStatus: z.lazy(() => offerTradeStatusZod.optional()),
});

export type TOffer = z.infer<typeof offerZod>;
