import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import {
  CreateListingDTO,
  SellWtbDTO,
} from '@typescript/dtos/listing/createListing.dto';
import {
  UpdateListingDTO,
  UpdateListingExpirationDTO,
} from '@typescript/dtos/listing/updateListing.dto';
import {
  GetListingsByTypeDTO,
  CheckListingStatusDTO,
} from '@typescript/dtos/listing/getListings.dto';
import {
  IdDTO,
  NameWithPaginationDTO,
  NameDTO,
} from '@typescript/dtos/shared/id.dto';
import { listingZod } from '@typescript/models/Listing.model';
import Moralis from 'moralis-v1';
import {
  resourceWithPaginatedZod,
  PaginationDTO,
} from '@typescript/dtos/shared/pagination.dto';

export const listingApi = createApi({
  reducerPath: ApiSlice.LISTINGS,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.LISTINGS],
  endpoints: (builder) => ({
    getActiveListings: builder.query({
      async queryFn(payload: GetListingsByTypeDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_ACTIVE_LISTINGS,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(listingZod).parse(response),
          };
        } catch (error) {
          throw Error(`getActiveListings: ${error}`);
        }
      },
      providesTags: [ApiTag.LISTINGS],
    }),
    getMyActiveListings: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_ACTIVE_LISTINGS,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(listingZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyActiveListings: ${error}`);
        }
      },
      providesTags: [ApiTag.LISTINGS],
    }),
    getMyDeletedListings: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_LISTINGS_DELETED,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(listingZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyActiveListings: ${error}`);
        }
      },
      providesTags: [ApiTag.LISTINGS],
    }),
    getMyInactiveListings: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_LISTINGS_EXPIRED,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(listingZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyActiveListings: ${error}`);
        }
      },
      providesTags: [ApiTag.LISTINGS],
    }),
    getMyListingsWithTx: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_LISTINGS_WITH_TX,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(listingZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyActiveListings: ${error}`);
        }
      },
      providesTags: [ApiTag.LISTINGS],
    }),
    getListingsByProjectName: builder.query({
      async queryFn(payload: NameWithPaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_LISTINGS_BY_PROJECT,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(listingZod).parse(response),
          };
        } catch (error) {
          throw Error(`getListingsByProject: ${error}`);
        }
      },
      providesTags: [ApiTag.LISTINGS],
    }),
    createWTB: builder.mutation({
      async queryFn(dto: CreateListingDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_WTB,
            dto,
          );
          return { data: listingZod.parse(response) };
        } catch (error) {
          throw Error(`createWTB: ${error}`);
        }
      },
    }),
    createWTS: builder.mutation({
      async queryFn(dto: CreateListingDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_WTS,
            dto,
          );
          return { data: listingZod.parse(response) };
        } catch (error) {
          throw Error(`createWTS: ${error}`);
        }
      },
    }),
    updateWTB: builder.mutation({
      async queryFn(dto: UpdateListingDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_WTB,
            dto,
          );
          return { data: listingZod.parse(response) };
        } catch (error) {
          throw Error(`updateWTB: ${error}`);
        }
      },
    }),
    updateExpiration: builder.mutation({
      async queryFn(dto: UpdateListingExpirationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_LISTING_EXPIRATION,
            dto,
          );
          return { data: response };
        } catch (error) {
          throw Error(`updateWTB: ${error}`);
        }
      },
    }),
    claimTokens: builder.mutation({
      async queryFn(dto: IdDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CLAIM_EXPIRED_LISTING_TOKENS,
            dto,
          );
          return { data: response };
        } catch (error) {
          throw Error(`claimTokens: ${error}`);
        }
      },
    }),
    updateWTS: builder.mutation({
      async queryFn(dto: UpdateListingDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_WTS,
            dto,
          );
          return { data: listingZod.parse(response) };
        } catch (error) {
          throw Error(`updateWTS: ${error}`);
        }
      },
    }),
    sellWTB: builder.mutation({
      async queryFn(dto: SellWtbDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.SELL_WTB,
            dto,
          );
          return { data: listingZod.parse(response) };
        } catch (error) {
          throw Error(`sellWTB: ${error}`);
        }
      },
    }),
    deleteListing: builder.mutation({
      async queryFn(dto: IdDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.DELETE_LISTING,
            dto,
          );
          return { data: listingZod.parse(response) };
        } catch (error) {
          throw Error(`deleteListing: ${error}`);
        }
      },
    }),
    updateListingInProgress: builder.mutation({
      async queryFn(dto: IdDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_LISTING_IN_PROGRESS,
            dto,
          );
          return { data: listingZod.parse(response) };
        } catch (error) {
          throw Error(`updateListingInProgress: ${error}`);
        }
      },
    }),
    checkListingStatus: builder.query({
      async queryFn(dto: CheckListingStatusDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CHECK_LISTING_STATUS,
            dto,
          );
          return { data: response };
        } catch (error) {
          throw Error(`updateListingInProgress: ${error}`);
        }
      },
    }),
    searchListings: builder.query({
      async queryFn(payload: NameDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.SEARCH_LISTINGS,
            payload,
          );
          return {
            data: listingZod.array().parse(response),
          };
        } catch (error) {
          throw Error(`getSearchProjects: ${error}`);
        }
      },
      providesTags: [ApiTag.LISTINGS],
    }),
  }),
});

export const {
  useGetMyActiveListingsQuery,
  useGetMyInactiveListingsQuery,
  useGetActiveListingsQuery,
  useCreateWTBMutation,
  useCreateWTSMutation,
  useUpdateExpirationMutation,
  useDeleteListingMutation,
  useSellWTBMutation,
  useUpdateWTBMutation,
  useClaimTokensMutation,
  useUpdateWTSMutation,
  useUpdateListingInProgressMutation,
  useGetListingsByProjectNameQuery,
  useLazyCheckListingStatusQuery,
  useLazySearchListingsQuery,
} = listingApi;
