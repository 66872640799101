import { Components } from '@mui/material';
import { OverridesOptions } from '@theme/types';
import type {} from '@mui/x-data-grid/themeAugmentation';
import customColors from './customColors';

export default function overrideComponents({
  palette,
}: OverridesOptions): Components {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '0 0 8px 0',
          backgroundColor: `${customColors.cardGrey}`,
          backgroundImage: 'none',
          border: `1px solid ${palette.primary.light}80`,

          '& .MuiFormControl-root:not(:last-child)': {
            marginRight: '8px',
          },

          '& label, & select & input::placeholder': {
            fontSize: '16px',
          },

          '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            paddingLeft: '0',
            '&:before,&:after': {
              display: 'none',
            },
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          borderRadius: '6px',
          backgroundColor: palette.error.light,
          boxShadow: 'none',
        },
        notchedOutline: {
          border: '0 !important',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: '90px',
          color: palette.primary.main,
          fontSize: '14px',
          letterSpacing: '0.17px',
          textAlign: 'start',
          border: 'none',
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          paddingBottom: '0.3rem',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontWeight: '400',
          fontSize: '14px',
          padding: '0.6rem 1rem',
          minWidth: 'unset',
        },
        contained: {
          backgroundColor: palette.primary.main,
          color: palette.background?.default,
        },
        outlined: {
          backgroundColor: palette.primary.contrastText,
          border: `1px solid ${palette.secondary.light}80`,
          fontWeight: '400',
          color: palette.primary.main,
          '&:hover': {
            borderColor: palette.primary.main,
          },
        },
        text: {
          backgroundColor: 'none',
          color: palette.text?.secondary,
          fontWeight: 400,
          '&:hover': {
            backgroundColor: 'transparent',
            color: palette.text?.primary,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          padding: '0.2rem',
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          height: '2px',
          width: '2.938rem',
          backgroundColor: palette.error.main,
          position: 'absolute',
          top: '47px',
          left: 0,
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: palette.warning.light,
          fontSize: '14px',
          color: palette.primary.main,
          borderRadius: '0',
          padding: '0.25rem 0.7rem',
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'red',
        },
      },
    },

    MuiDataGrid: {
      styleOverrides: {
        root: {
          paddingBottom: '0',
          border: 'none',
          '& .MuiDataGrid-cell--withRenderer': {
            ':focus-within': {
              outline: 'none',
            },
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            ':focus': {
              outline: 'none',
            },
            border: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            border: 'none',
          },
          '& .MuiDataGrid-columnHeader': {
            ':focus': {
              outline: 'none',
            },
            ':focus-within': {
              outline: 'none',
            },
          },
        },

        virtualScrollerContent: {
          backgroundColor: palette.secondary.main,
        },
        virtualScroller: {
          backgroundColor: 'transparent',
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '15px',
          color: palette.primary.main,
          '&:hover': {
            color: palette.primary.main,
          },
        },
        button: {
          fontWeight: 400,
          fontSize: '14px',
          color: palette.text?.primary,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '0.5rem',
        },
        standard: {
          fontSize: '12px',
        },
        icon: {
          color: palette.text?.primary,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.main,
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.secondary.contrastText,
          backdropFilter: 'blur(20px)',
          border: `1px solid ${palette.secondary.light} !important`,
          fontSize: 12,
          padding: '10px',
          borderRadius: '6px 12px 12px 12px',
        },
      },
    },
  };
}
