import { Box, Button, styled, ButtonProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { toRem } from '@utils/functions';

export const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  maxWidth: '1200px',
  marginInline: 'auto',
  gap: '0.7rem',
  [theme.breakpoints.down('2lg')]: {
    maxWidth: '711px',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '450px',
    gridTemplateColumns: '1fr',
  },
}));

export const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  padding: '1rem',
  ':hover': {
    backgroundColor: '#232344',
    cursor: 'pointer',
  },
}));

// export const tableStyle = (theme: Theme) => ({
//   '.MuiDataGrid-main': {
//     borderRadius: '6px',
//     border: `1px solid ${theme.palette.secondary.light}`,
//     paddingBottom: '0.5rem',
//     backgroundColor: theme.palette.secondary.main,
//   },
//   '.MuiDataGrid-columnHeaders': {
//     backgroundColor: theme.palette.secondary.main,
//   },
//   '& .MuiDataGrid-columnHeaderTitle': {
//     ...theme.typography.h5,
//     fontWeight: 500,
//     color: theme.palette.primary.main,
//     opacity: '50%',
//   },
//   '& .MuiDataGrid-cellContent': {
//     ...theme.typography.h5,
//     color: theme.palette.primary.main,
//     paddingRight: '3rem',
//     maxWidth: '100%',
//   },
//   '& .MuiTablePagination-selectLabel': {
//     ...theme.typography.h6,
//     fontWeight: 400,
//     color: theme.palette.text.secondary,
//   },
//   '& .MuiSelect-icon': {
//     color: theme.palette.text.secondary,
//   },
//   '& .MuiTablePagination-select': {
//     marginTop: '0.3rem',
//   },
// });

interface CustomButtonProps extends ButtonProps {
  children: ReactNode;
}

export function SeeMoreButton({
  children,
  sx: propStyles = {},
  ...props
}: CustomButtonProps) {
  const theme = useTheme();
  return (
    <Button
      sx={{
        backgroundColor: theme.palette.secondary.main,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        border: `1px solid ${theme.palette.secondary.light}`,
        fontSize: '15px',
        fontWeight: '400',
        borderTop: 'none',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        ':hover': {
          '& div': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        [theme.breakpoints.down('lg')]: {
          borderRadius: '6px',
          marginInline: 'auto',
          border: 0,
          backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        ...propStyles,
      }}
      {...props}
    >
      <Box
        sx={{
          padding: theme.spacing(1),
          borderRadius: toRem(4),
          backgroundColor: theme.palette.primary.contrastText,
          border: `1px solid ${theme.palette.primary.light}`,
          transition: `background-color ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
        }}
      >
        {children}
      </Box>
    </Button>
  );
}
