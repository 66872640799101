import { GridValidRowModel } from '@mui/x-data-grid';
import customColors from '@theme/customColors';
import { toRem } from '@utils/functions';

export const gridRow: GridValidRowModel = {
  flex: 1,
  align: 'left',
  headerAlign: 'left',
  minWidth: 125,
};

export const DataGridCustomStyles = {
  width: '100%',
  marginTop: '0',
  flex: 'unset',

  '& .MuiDataGrid-main': {
    border: `1px solid ${customColors.borderGray}`,
    borderRadius: '0.5rem',
    backgroundColor: customColors.darkBlue,
  },

  '& .MuiDataGrid-virtualScroller': {
    maxHeight: 'calc(100vh - 366px)',
    overflow: 'auto!important',
    paddingBottom: '2px',
  },

  '& .MuiSelect-icon': {
    display: 'none',
  },

  '& .MuiDataGrid-footerContainer .MuiInputBase-root': {
    marginRight: toRem(16),
    marginLeft: toRem(4),
  },

  '& .MuiDataGrid-footerContainer .MuiTablePagination-select': {
    paddingLeft: toRem(4),
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      right: '0',
      top: '50%',
      width: toRem(24),
      height: toRem(24),
      transform: 'translateY(-50%) rotateZ(0)',
      backgroundSize: 'cover',
      transition: `transform 0.3ms ease-in-out`,
      // eslint-disable-next-line max-len
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <path fill="${encodeURIComponent(
        customColors.shadedWhite,
        // eslint-disable-next-line max-len
      )}" d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"/></svg>')`,
    },

    '&[aria-expanded="true"]:before': {
      transform: 'translateY(-50%) rotateZ(180deg)',
    },
  },

  '& .MuiDataGrid-footerContainer .MuiTablePagination-actions': {
    marginLeft: toRem(10),

    '& .MuiButtonBase-root': {
      padding: '0',
    },
  },

  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'transparent',
  },

  '& .MuiDataGrid-row': {
    borderTop: `1px solid ${customColors.borderGray}80`,
  },

  '& .MuiDataGrid-virtualScrollerContent': {
    backgroundColor: 'transparent',
  },

  '& .MuiDataGrid-columnHeaderTitleContainer': {
    maxWidth: '100%',
    whiteSpace: 'unset',
    overflow: 'unset',
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: toRem(13),
    fontWeight: 500,
    lineHeight: '1.2',
    whiteSpace: 'unset',
    color: customColors.shadedWhite,
  },

  '& .MuiDataGrid-cell': {
    paddingRight: '0.5rem',
  },

  '& .MuiDataGrid-cellContent': {
    fontSize: toRem(14),
    fontWeight: 400,
    color: customColors.white,
    maxWidth: '100%',
  },

  '& .MuiTablePagination-selectLabel': {
    fontSize: toRem(14),
    fontWeight: 400,
    color: customColors.white,
    paddingLeft: '0',
  },
};

export const DataGridSeeMoreStyles = {
  ...DataGridCustomStyles,
  '.MuiDataGrid-main': {
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '0',
  },
};
