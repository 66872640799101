import { Box, Typography, styled } from '@mui/material';
import customColors from '@theme/customColors';
import { flexCentered } from '@styles/shared/SCss';

export const HeaderProject = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '1rem',
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
}));

export const ProjectDetails = styled(Box)(({ theme }) => ({
  paddingBlock: '1.5rem',
  display: 'grid',
  gap: '0.5rem',
  // gridTemplateColumns: '1fr 1fr 1fr 1.5fr 1.5fr',
  gridTemplateColumns: 'repeat(3, 1fr)',
  [theme.breakpoints.down('md')]: {
    paddingInline: '1rem',
    gridTemplateColumns: '1fr 1fr',
  },
}));

export const ProjectDetailsCard = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.main}`,
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  padding: '1rem',
  height: '125px',
}));

export const ProjectTable = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: `${theme.palette.secondary.main}`,
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '6px 6px 6px 0',
  marginTop: '1.5rem',
}));

const ProjectOverview = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.main}`,
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '6px 6px 6px 6px',
  marginTop: '1.5rem',
  padding: '1rem',
  paddingTop: '1.5rem',
}));

export const WrapperContainer = styled(ProjectOverview)(() => ({
  padding: '1rem',
  flexGrow: '1',
}));

export const ProjectOverviewSidebar = styled(Box)(() => ({
  marginTop: '3.5rem',
  paddingInline: '1.5rem',
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column',
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  marginBottom: '0.8rem',
}));

export const OverviewTitle = styled(Typography)(() => ({
  fontSize: '28px',
  fontWeight: 700,
}));

export const OverviewSubTitle = styled(Typography)(() => ({
  fontSize: '15px',
  fontWeight: 500,
  color: customColors.orange,
}));

export const ProjectTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: 700,
  marginLeft: '0.5rem',
}));

export const SecondProjectTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: 400,
  marginLeft: '0.5rem',
  opacity: 0.5,
}));

export const ProjectTeamCardContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '0.5rem',

  [theme.breakpoints.down('2lg')]: {
    gridTemplateColumns: '1fr',
  },
  [theme.breakpoints.down('xsm')]: {
    gridTemplateColumns: '1fr ',
  },
}));

export const ProjectTeamCard = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.main}`,
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  padding: '1rem',
}));

export const ProjectTeamCardText = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: 400,
  opacity: 0.7,
  lineHeight: '150%',
}));

export const ProjectPartnersContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: '0.5rem',
  [theme.breakpoints.down('2lg')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('xsm')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const ProjectPartnersCard = styled(ProjectOverview)(() => ({
  ...flexCentered,
  margin: '0',
  padding: '2rem',
}));

export const ProjectSocialCardContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(7,1fr)',
  gap: '0.5rem',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(4,1fr)',
  },
  [theme.breakpoints.down('xsm')]: {
    gridTemplateColumns: 'repeat(3,1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(2,1fr)',
  },
}));

export const ProjectSocialCard = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.secondary.main}`,
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  padding: 'calc(calc(100% - 54px) / 2) 0',
  ...flexCentered,
  '&>*': { display: 'flex' },
}));
