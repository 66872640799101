import { PaletteMode, Theme, ThemeOptions, createTheme } from '@mui/material';
import createPalette from '@theme/palette';
import overrideComponents from '@theme/overrides';
import createTypography from '@theme/typography';
import breakpoints from '@theme/breakPoints';

const theme = (mode: PaletteMode): Theme => {
  const newPalette = createPalette(mode);
  const newTypography = createTypography(newPalette);

  const themeOptions: ThemeOptions = {
    typography: newTypography,
    palette: newPalette,
    breakpoints,
  };

  const newTheme = createTheme(themeOptions);

  newTheme.components = overrideComponents({
    palette: newPalette,
    typography: newTypography,
  });

  return newTheme;
};

export default theme;
