import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import { UpdateProjectDetailsDto } from '@typescript/dtos/project/updateProject.dto';
import { projectDetailsZod } from '@typescript/models/Project.model';
import Moralis from 'moralis-v1';

export const projectDetailApi = createApi({
  reducerPath: ApiSlice.PROJECT_DETAIL,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.PROJECT_DETAIL],
  endpoints: (builder) => ({
    updateProjectDetails: builder.mutation({
      async queryFn(payload: UpdateProjectDetailsDto) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_PROJECT_DETAIL,
            payload,
          );
          return { data: projectDetailsZod.parse(response) };
        } catch (error) {
          throw Error(`updateProjectDetails: ${error}`);
        }
      },
    }),
  }),
});

export const { useUpdateProjectDetailsMutation } = projectDetailApi;
