import { z } from 'zod';
import { Coin } from '@constants/CCoin';
import { userZod } from '@typescript/models/User.model';
import { projectZod } from './Project.model';

export const transactionInvestmentZod = z.object({
  id: z.string(),
  project: projectZod,
  from: userZod,
  to: z.string(),
  investmentAmount: z.number(),
  investmentAmountWithFee: z.number(),
  transactionHash: z.string(),
  feeInPercentage: z.number(),
  totalTokens: z.number(),
  coin: z.nativeEnum(Coin),
  createdAt: z.string(),
  confirmed: z.boolean(),
});

export type TTransactionInvestment = z.infer<typeof transactionInvestmentZod>;
