const customColors = {
  darkBlue: '#13151B',
  darkBlue50: '#13151B80',
  darkBlue65: '#13151BA6',
  darkBlue70: '#13141BB2',
  boxShadow45: '#00000073',
  boxColor: '#8D99AE',
  boxColor15: 'rgba(141, 153, 174, 0.15)',
  white: '#EDF2F4',
  primaryWithOpacity50: 'rgba(237, 242, 244, 0.5)',
  shadedWhite: '#6D7691',
  secondaryMain: '#13141B',
  errorMain: '#DB633E',
  shadowRed: '#D219344D',
  successMain: '#47E4AC',
  mainBackground: 'linear-gradient(180deg, #1A1A30 0%, #18182C 100%)',
  ebonyClay: '#2B2D4233',
  lightBlue: '#8d99ae1a',
  lightDark: '#8d99ae33',
  borderGray: '#37383D',
  baliHai: '#2E2E42',
  chartBlue: '#1BBDE0',
  blue: '#32A6CD',
  shadowBlue: '#47d1fc4d',
  darkBlueOpacity: '#169bfc4d',
  darkBlueText: '#042748',
  lightOrange: '#FFB800',
  lightDarkOrange: '#E49C47',
  shadowOrange: '#F193264D',
  darkGreenText: '#0A442F',
  limeGreen: '#75FB91',
  shadowGreen: '#67FC864D',
  warmGreen: '#83DF0E',
  darkOrangeText: '#513C05',
  orange: '#F2A93B',
  darkGray: '#35364C',
  riverStyxDarkBlue: '#161820',
  warmGreyGradient: 'linear-gradient(90deg, #93D011 0%, #83DF0E 100%)',
  warmGrey30: 'rgba(124, 208, 17, 0.3)',
  antarcticGrey: '#36393F',
  borderDarkColor: '#2B2C30',
  iconsShade: '#666980',
  sidebarCollapseButtonColor: '#252831',
  midnightViolet: '#6A76A9',
  blueGradient: 'linear-gradient(to right, #18BCF0 100%, #47BEE4 100%)',
  opacityBlueGradient:
    'linear-gradient(to right, #18BCF033 100%, #47BEE433 100%)',
  limegreenGradient: 'linear-gradient(to right, #24FF52 100%, #75FB91 100%)',
  opacityLimegreenGradient:
    'linear-gradient(to right, #24FF5233 100%, #75FB9133 100%)',
  orangeGradient: 'linear-gradient(to right, #F98C0C 100%, #E49C47 100%)',
  opacityOrangeGradient:
    'linear-gradient(to right, #F98C0C33 100%, #E49C4733 100%)',
  darkGreenGradient: 'linear-gradient(180deg, #24FF5200 0%, #24FF5233 100%)',
  redGradient: 'linear-gradient(180deg, #F60B0B00 0%, #F60B0B33 100%)',
  darkOrangeGradient: 'linear-gradient(180deg, #F98C0C00 0%, #F98C0C33 100%)',
  midnightSeaRadialGradientOpacity50:
    'radial-gradient(circle, #525D8E00 0%, #525D8E80 100%)',
  grey: '#23272E',
  cardGrey: '#1B1D25',
  washedBlackOpacity75: '#1E202ABF',
};

export default customColors;
