import { Coin } from '@constants/CCoin';
import { OfferStatus } from '@constants/COffer';
import { z } from 'zod';

export const updateOfferZod = z.object({
  offeredPrice: z.number(),
  offerCoin: z.nativeEnum(Coin),
  offeredTokenPrice: z.number(),
  id: z.string(),
});

export const setStatusOfferZod = z.object({
  offerStatus: z.nativeEnum(OfferStatus),
  id: z.string(),
});

export type UpdateOfferDTO = z.infer<typeof updateOfferZod>;
export type SetStatusOfferDTO = z.infer<typeof setStatusOfferZod>;
