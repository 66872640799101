import { CSSObject, Theme, Drawer, styled, Typography } from '@mui/material';
import customColors from '@theme/customColors';
import { toRem } from '@utils/functions';

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
});

interface DrawerIn {
  open: boolean;
  theme: Theme;
}
export const DrawerContainer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerHeader = styled('div')(({ open }: DrawerIn) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: `${open ? 'space-between' : 'center'}`,
  padding: `${toRem(16)} ${toRem(12)} 0px 1.25rem`,
}));

export const SidebarSubTitle = styled(Typography)(
  ({ open }: { open: boolean }) => ({
    color: customColors.iconsShade,
    display: `${open ? 'block' : 'none'}`,
  }),
);
