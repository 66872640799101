/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// Save the original console.warn method
const originalConsoleWarn = console.warn;

// Override the console.warn method
console.warn = (message?: any, ...optionalParams: any[]): void => {
  // Check if the message contains the specific warning you want to filter out
  if (
    typeof message === 'string' &&
    message.includes(
      'Moralis: You are not using the latest version of the SDK.',
    )
  ) {
    return; // Do nothing, effectively filtering out the warning
  }
  // Call the original console.warn method for all other warnings
  originalConsoleWarn.apply(console, [message, ...optionalParams]);
};

export {};
