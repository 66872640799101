import { Coin } from '@constants/CCoin';
import { z } from 'zod';
import { userZod } from './User.model';
import { projectZod } from './Project.model';

export enum GetTradeTxType {
  BOUGHT = 'BOUGHT',
  SOLD = 'SOLD',
}

export enum TradeType {
  WTS = 'WTS',
  WTB = 'WTB',
  OFFER = 'OFFER',
  MANUAL = 'MANUAL',
}

export const transactionZod = z.object({
  id: z.string(),
  value: z.number(),
  from: userZod,
  to: userZod,
  tradeType: z.nativeEnum(TradeType),
  coin: z.nativeEnum(Coin),
  createdAt: z.string(),
  hash: z.string(),
  project: projectZod,
  tokenPrice: z.number(),
  tokenAmount: z.number(),
  listingWorth: z.number(),
  notes: z.string().optional(),
  confirmed: z.boolean(),
});

export type TTransaction = z.infer<typeof transactionZod>;

export interface TTokenTransferParameters {
  amount: number;
  receiverAddress: string;
  coin: Coin;
  listingId: string;
  tokenPrice: number;
  type: TradeType;
  tokenAmount: number;
}

export interface TStabileTransferParameters {
  amount: number;
  receiverAddress: string;
  coin: Coin;
}

export interface TTxResponse {
  chainId: number;
  confirmations: number;
  creates: null;
  data: string;
  from: string;
  gasLimit: { _hex: string; _isBigNumber: boolean };
  gasPrice: { _hex: string; _isBigNumber: boolean };
  hash: string;
  maxFeePerGas: { _hex: string; _isBigNumber: boolean };
  maxPriorityFeePerGas: { _hex: string; _isBigNumber: boolean };
  nonce: number;
  value: { _hex: string; _isBigNumber: boolean };
  wait: (confirmations: number) => Promise<unknown>;
}
