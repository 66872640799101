import { Box, useTheme } from '@mui/material';
import { MainBox } from '@styles/shared/SBoxes';
import ProfileImage from '@components/account/ProfileImage';
import AccountCoinsComp from '@components/account/AccountCoinsComp';
import AccountUserInfo from '@components/account/AccountUserInfo';
import AccountAdmin from '@components/account/AccountAdmin';
import useUser from '@hooks/useUser';

function Account() {
  const { isAdmin } = useUser();
  const theme = useTheme();

  return (
    <MainBox
      sx={{
        mt: '3.6rem',
        pb: { xs: 10, md: 0 },
        px: { xs: 1.5, sm: 3 },
      }}
    >
      <Box
        sx={{
          maxWidth: { md: '711px', lg: '900px' },
          margin: { xs: '0 auto', lg: 0 },
        }}
      >
        {isAdmin && <AccountAdmin />}

        <Box
          sx={{
            display: { xs: 'grid', md: 'flex' },
            alignItems: 'start',
            gap: '3rem',
            marginTop: '1rem',
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              },
            }}
          >
            <ProfileImage />

            <AccountCoinsComp />
          </Box>
          <AccountUserInfo />
        </Box>
        {/* <AccountVerificationCom /> */}
      </Box>
    </MainBox>
  );
}

export default Account;
