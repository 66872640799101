/* eslint-disable import/no-unused-modules */
import { z } from 'zod';

export const feeZod = z.object({
  id: z.string(),
  lvWallet: z.string(),
  receiverWallet: z.string(),
  feeInUsd: z.number(),
  feeInTokens: z.number(),
  feeInPercentage: z.number(),
  feeInPercentagePrivate: z.number(),
});

const feeAttributesZod = z.object({
  feeInUsd: z.number(),
  feeInTokens: z.number(),
  feeInPercentage: z.number(),
  receiverWallet: z.string(),
  lvWallet: z.string(),
  feeInPercentagePrivate: z.number(),
});

export type TFeeAttributes = z.infer<typeof feeAttributesZod>;

export type TFee = z.infer<typeof feeZod>;
