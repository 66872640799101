import { ListingType } from '@constants/CListings';
import { z } from 'zod';
import { TradeType } from '@typescript/models/TradeTransaction.model';
import { paginationZod } from '../shared/pagination.dto';
import { idZod } from '../shared/id.dto';

export const getListingByTypeZod = z
  .object({
    type: z.nativeEnum(ListingType),
  })
  .merge(paginationZod);

export const checkListingStatusZod = z
  .object({
    tokenPrice: z.number(),
    amount: z.number(),
    type: z.nativeEnum(TradeType),
  })
  .merge(idZod);

export type CheckListingStatusDTO = z.infer<typeof checkListingStatusZod>;

export type GetListingsByTypeDTO = z.infer<typeof getListingByTypeZod>;
