/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { projectApi } from './api/projectApi';
import { allocationApi } from './api/allocationApi';
import { userApi } from './api/userApi';
import { listingApi } from './api/listingApi';
import { offerApi } from './api/offerApi';
import { projectDetailApi } from './api/projectDetailApi';
import { transactionInvestmentApi } from './api/transactionInvestmentApi';
import { utilApi } from './api/utilApi';
import { transactionApi } from './api/transactionApi';
import connectSlice from './slices/connectSlice';
import { RootState } from './storeTypes';
import { ethInvestmentApi } from './api/ethInvestmentApi';
import { ethAllocationApi } from './api/ethAllocationApi';

const rootReducer = combineReducers({
  [projectApi.reducerPath]: projectApi.reducer,
  [allocationApi.reducerPath]: allocationApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [listingApi.reducerPath]: listingApi.reducer,
  [offerApi.reducerPath]: offerApi.reducer,
  [projectDetailApi.reducerPath]: projectDetailApi.reducer,
  [transactionInvestmentApi.reducerPath]: transactionInvestmentApi.reducer,
  [utilApi.reducerPath]: utilApi.reducer,
  [transactionApi.reducerPath]: transactionApi.reducer,
  [ethInvestmentApi.reducerPath]: ethInvestmentApi.reducer,
  [ethAllocationApi.reducerPath]: ethAllocationApi.reducer,
  connect: connectSlice,
});

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(projectApi.middleware)
      .concat(allocationApi.middleware)
      .concat(userApi.middleware)
      .concat(listingApi.middleware)
      .concat(offerApi.middleware)
      .concat(projectDetailApi.middleware)
      .concat(transactionInvestmentApi.middleware)
      .concat(transactionApi.middleware)
      .concat(ethInvestmentApi.middleware)
      .concat(ethAllocationApi.middleware)
      .concat(utilApi.middleware),
});

// export const persistor = persistStore(store);

// type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
