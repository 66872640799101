import { Box, styled } from '@mui/material';

export const ProjectsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 330px))',
  gridTemplateRows: 'auto',
  marginTop: '1rem',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 346px))',
    justifyContent: 'center',
  },
}));
