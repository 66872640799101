import { PaletteMode } from '@mui/material';
import colors from '@theme/customColors';
import { CreateCustomPalette, CustomPalette } from '@theme/types';

// ? default pallet
const darkPallette: CustomPalette = {
  background: {
    paper: colors.mainBackground,
    default: colors.darkBlue,
  },
  primary: {
    main: colors.white,
    light: colors.borderGray,
    contrastText: colors.grey,
    dark: colors.iconsShade,
  },
  secondary: {
    main: colors.secondaryMain,
    light: colors.antarcticGrey,
    dark: colors.washedBlackOpacity75,
    contrastText: colors.darkBlue50,
  },
  error: {
    main: colors.errorMain,
    light: colors?.lightDark,
  },
  warning: {
    main: colors.blue,
    light: colors.lightOrange,
  },
  success: {
    main: colors.warmGreen,
  },
  text: {
    primary: colors.white,
    secondary: colors.shadedWhite,
  },
};

const lightPalette: CustomPalette = {
  background: {
    paper: colors.white,
    default: colors.boxColor,
  },
  primary: {
    main: colors.darkBlue,
    light: colors.lightBlue,
  },
  secondary: {
    main: colors.ebonyClay,
    light: colors.baliHai,
  },
  error: {
    main: colors.errorMain,
    light: colors.white,
  },
  warning: {
    main: colors.lightBlue,
    light: colors.white,
  },
  success: {
    main: colors.successMain,
  },
  text: {
    primary: colors.white,
    secondary: colors.shadedWhite,
  },
};

const createPalette = (mode: PaletteMode): CreateCustomPalette => {
  const currentPalette = mode === 'light' ? lightPalette : darkPallette;

  return {
    mode,
    ...currentPalette,
  };
};

export default createPalette;
