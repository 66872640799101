import { z } from 'zod';

export const tierZod = z.object({
  id: z.number(),
  name: z.string(),
  multiplier: z.number(),
});

export const tokenTierZod = tierZod.extend({
  minimumAmount: z.number(),
});

export const getEligibilityZod = z.object({
  nftTier: tierZod.nullable(),
  holdingTier: tokenTierZod.nullable(),
  balance: z.number(),
  eligibleToInvest: z.number(),
});
