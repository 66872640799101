import { Box, Button, TextareaAutosize, styled } from '@mui/material';
import customColors from '@theme/customColors';

export const ProjectCreateDetails = styled(Box)(({ theme }) => ({
  paddingBlock: '1.5rem',
  borderBottom: `1px solid ${theme.palette.secondary.light}`,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateAreas: `
  "div1 div2 div3"
  "div4 div5 div6"
  `,
  gap: '0.5rem',
  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `
    "div1 div2"
    "div3 div4"
    "div5 div6"
    `,
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
    "div1" "div2" "div3" "div4" "div5" "div6"`,
  },
}));

export const AddTeamCardStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.7rem',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.secondary.light}`,
  padding: '1rem',
  borderRadius: '6px',
}));

export const TextArea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  minHeight: '100px',
  backgroundColor: `${theme.palette.secondary.main}`,
  borderRadius: '6px',
  border: `1px solid ${theme.palette.secondary.light}`,
  outline: 'none',
  color: theme.palette.primary.main,
  padding: '0.5rem',
}));

export const CloseBtn = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-end',
  padding: '0.5rem',
  minWidth: 'unset',
  position: 'absolute',
  top: '-20px',
  right: '3px',
  borderRadius: '4px',
  ':hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

// export const CloseInputBtn = styled(Button)(() => ({
//   margin: '0',
//   borderRadius: '4px',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   position: 'absolute',
//   right: '-1px',
//   top: '0',
//   minWidth: 'unset',
//   minHeight: 'unset',
//   padding: '0.5rem',
//   zIndex: 1,
//   ':hover': {
//     backgroundColor: customColors.baliHai,
//   },
// }));

export const AddPartnerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0',
  padding: '0',
  minHeight: '125px',
  ':hover': {
    backgroundColor: customColors.baliHai,
  },
}));

export const RoadmapAddBtnStyled = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  padding: '1rem',
  border: `1px solid ${theme.palette.secondary.light}`,
  width: 200,
  height: 100,
  borderRadius: '6px',
  ':hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export const SocialCard = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  padding: '0',
}));

// export const AddInputBox = styled(Box)(() => ({
//   position: 'relative',
//   width: '200px',
//   paddingTop: '2rem',
// }));
