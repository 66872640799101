import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import { currentUserZod, userZod } from '@typescript/models/User.model';
import Moralis from 'moralis-v1';
import { UpdateUserInfoDTO } from '@typescript/dtos/user/updateUserInfo.dto';
import { UpdateUserDTO } from '@typescript/dtos/user/updateUser.dto';
import { ENV } from '@utils/myEnv';

export const userApi = createApi({
  reducerPath: ApiSlice.USER,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.USER],
  endpoints: (builder) => ({
    getCurrentUser: builder.query({
      async queryFn() {
        const userFromLocalStorage = localStorage.getItem(
          `Parse/${ENV.serverId}/currentUser`,
        );
        if (!userFromLocalStorage) {
          return {
            data: undefined,
          };
        }
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_CURRENT_USER,
          );
          return {
            data: currentUserZod.parse(response),
          };
        } catch (error) {
          localStorage.clear();
          return { data: undefined };
        }
      },
      providesTags: [ApiTag.USER],
    }),
    updateUser: builder.mutation({
      async queryFn(dto: UpdateUserDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_USER,
            dto,
          );
          return { data: userZod.parse(response) };
        } catch (error) {
          throw Error(`updateUser: ${error}`);
        }
      },
    }),
    updateUserInfo: builder.mutation({
      async queryFn(dto: UpdateUserInfoDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_USER_INFO,
            dto,
          );
          return { data: userZod.parse(response) };
        } catch (error) {
          throw Error(`updateUserInfo: ${error}`);
        }
      },
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
  useUpdateUserInfoMutation,
} = userApi;
