import { z } from 'zod';

export const userAllocationStatsZod = z.object({
  totalLockedValue: z.number(),
  totalUnlockedValue: z.number(),
  totalInvestment: z.number(),
  totalAssetWorth: z.number(),
});

export const projectAllocationStatsZod = z.object({
  projectMaxLockedTokens: z.number(),
});

export type UserAllocationStatsDTO = z.infer<typeof userAllocationStatsZod>;
