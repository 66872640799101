import { Box, styled, BoxProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { toRem } from '@utils/functions';

export const TabContainer = styled(Box)(({ theme }) => ({
  borderRadius: '6px',
  border: `1px solid ${theme.palette.secondary.light}80`,
  height: '32px',
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: theme.palette.secondary.main,
}));

interface CustomBoxProps extends BoxProps {
  children: ReactNode;
}

export function MainBox({
  children,
  sx: propStyles = {},
  ...props
}: CustomBoxProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',

        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          left: '0',
          right: '14px',
          height: toRem(88),
        },

        '&:before': {
          top: toRem(40),
          // eslint-disable-next-line max-len
          backgroundImage: `linear-gradient(0deg, ${theme.palette.background.default}00 0%, ${theme.palette.background.default} 100%)`,
          [theme.breakpoints.down('lg')]: {
            top: toRem(68),
          },
        },

        '&:after': {
          bottom: '0',
          // eslint-disable-next-line max-len
          backgroundImage: `linear-gradient(180deg, ${theme.palette.background.default}00 0%, ${theme.palette.background.default} 100%)`,
          [theme.breakpoints.down('md')]: {
            backgroundImage: 'none',
          },
        },
      }}
      {...props}
    >
      <Box
        sx={{
          padding: '0 15px 15px 15px',
          marginTop: toRem(58),
          width: '100%',
          display: 'flex',
          flexDirection: 'column',

          '&:after': {
            content: "''",
            height: toRem(103),
            width: '100%',
            flexShrink: '0',
          },

          [theme.breakpoints.down('lg')]: {
            marginTop: toRem(71),
            maxHeight: 'calc(100vh - 71px)',
          },
          ...propStyles,
        }}
        zIndex="2"
      >
        {children}
      </Box>
    </Box>
  );
}
