import { z } from 'zod';
import { ethProjectZod } from './EthProject.model';
import { userZod } from './User.model';

export const ethInvestmentZod = z.object({
  id: z.string(),
  from: userZod,
  to: z.string(),
  ethProject: ethProjectZod,
  hash: z.string(),
  investment: z.number(),
  confirmed: z.boolean(),
  createdAt: z.string(),
});

export type TEthInvestment = z.infer<typeof ethInvestmentZod>;
