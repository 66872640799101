export enum ApiTag {
  USER = 'User',
  ALLOCATIONS = 'Allocations',
  LISTINGS = 'Listings',
  OFFERS = 'Offers',
  PROJECTS = 'Projects',
  PROJECT_DETAIL = 'ProjectDetails',
  TRANSACTIONS_INVESTMENT = 'TransactionsInvestment',
  FEE = 'Fee',
  TOKEN_DISTRIBUTIONS = 'TokenDistribution',
  UTILS = 'utils',
  TRANSACTIONS = 'Transactions',
  ETH_PROJECTS = 'EthProjects',
  ETH_INVESTMENTS = 'EthInvestments',
  ETH_ALLOCATIONS = 'EthAllocations',
}

export enum ApiSlice {
  USER = 'User',
  ALLOCATIONS = 'Allocations',
  LISTINGS = 'Listings',
  OFFERS = 'Offers',
  PROJECTS = 'Projects',
  PROJECT_DETAIL = 'ProjectDetails',
  TRANSACTIONS_INVESTMENT = 'TransactionsInvestment',
  TRANSACTIONS = 'Transactions',
  FEE = 'Fee',
  TOKEN_DISTRIBUTIONS = 'TokenDistribution',
  UTILS = 'utils',
  ETH_PROJECT = 'EthProject',
  ETH_INVESTMENT = 'EthInvestment',
  ETH_ALLOCATION = 'EthAllocation',
}
