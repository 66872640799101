// connectSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userApi } from '@store/api/userApi';
import { SOCIAL_STEPS } from '@typescript/MultistepSocial.enum';

export type ActionFunction = () => void;

interface ConnectState {
  showLoginPopup: boolean;
  hasSocials: boolean;
  socialStep: number;
}

const initialState: ConnectState = {
  showLoginPopup: false,
  hasSocials: false,
  socialStep: SOCIAL_STEPS.FIRST_STEP,
};

const connectSlice = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    setShowLoginPopup(state, action: PayloadAction<boolean>) {
      state.showLoginPopup = action.payload;
    },
    setSocialStep(state, action: PayloadAction<number>) {
      state.socialStep = action.payload;
    },
    hideLoginPopup(state) {
      state.showLoginPopup = false;
      state.socialStep = SOCIAL_STEPS.FIRST_STEP;
    },
    setHasSocials(state, action: PayloadAction<boolean>) {
      state.hasSocials = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getCurrentUser.matchFulfilled,
      (state, action) => {
        const currentUser = action.payload;
        state.hasSocials = !!(
          currentUser &&
          currentUser.info.discordName !== ' ' &&
          currentUser.info.telegramLink !== ' '
        );
      },
    );
  },
});

export const {
  setShowLoginPopup,
  setSocialStep,

  hideLoginPopup,
  setHasSocials,
} = connectSlice.actions;

export default connectSlice.reducer;
