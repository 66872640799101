import {
  partnersCardZod,
  socialCardZod,
  teamCardZod,
} from '@typescript/models/Project.model';
import { z } from 'zod';

const updateProjectZod = z.object({
  id: z.string(),
  name: z.string().optional(),
  image: z.string().optional(),
  status: z.number().optional(),
  totalLockedTokens: z.number().optional(),
  totalUnlockedTokens: z.number().optional(),
  chain: z.string().optional(),
  networkImage: z.string().optional(),
  tokenPrice: z.number().optional(),
  tokenAddress: z.string().optional(),
  fundraisingTarget: z.number().optional(),
  minInvestment: z.number().optional(),
  maxInvestment: z.number().optional(),
  symbol: z.string().optional().optional(),
  hidden: z.boolean().optional(),
});

export type UpdateProjectDto = z.infer<typeof updateProjectZod>;

const updateProjectDetailsZod = z.object({
  id: z.string(),
  overviewText: z.string().optional(),
  partnersCards: z.array(partnersCardZod).optional(),
  teamCards: z.array(teamCardZod).optional(),
  socialCards: z.array(socialCardZod).optional(),
});

export type UpdateProjectDetailsDto = z.infer<typeof updateProjectDetailsZod>;

export const recalculateExcelAllocationZod = z.object({
  wallet: z.string().startsWith('0x').trim().toLowerCase(),
  investment: z.number().positive(),
});

export const recalculateExcelProjectZod = z.object({
  projectName: z.string().min(2),
  allocations: z.array(recalculateExcelAllocationZod),
});

export type RecalculateExcelProjectDto = z.infer<
  typeof recalculateExcelProjectZod
>;
