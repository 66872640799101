import MyListingsTableResponsive from '@components/my-listings/responsive/MyListingsTableResponsive';
import MyListingsTable from '@components/my-listings/MyListingsTable';
import { MyListingsContentType } from '@constants/CContentType';
import useListings from '@hooks/useListings';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MainBox, TabContainer } from '@styles/shared/SBoxes';
import { TabBtn, activeTabStyle } from '@styles/shared/SButtons';
import { TMyListingsTab } from '@typescript/TTabs';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUser from '@hooks/useUser';
import { TDataActions } from '@typescript/TDataActions';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import { Coin } from '@constants/CCoin';
import { OfferAction, ListingAction } from '@constants/CDataActions';
import DeleteListingModal from '@components/modals/listings/DeleteListingModal';
import ModifyWTBListingModal from '@components/modals/listings/ModifyWTBListingModal';
import ModifyWTSListingModal from '@components/modals/listings/ModifyWTSListingModal';
import SellWTBModal from '@components/modals/listings/SellWTBModal';
import { ListingType, MyListingRequestType } from '@constants/CListings';
import WtbTxModals from '@components/modals/transactions/WtbTxModals';
import { TradeModalStep } from '@constants/CTransfer';
import OfferTxModals from '@components/modals/transactions/OfferTxModals';
import { sellWTBZod } from '@typescript/dtos/listing/createListing.dto';
import { updateListingZod } from '@typescript/dtos/listing/updateListing.dto';
import { idZod } from '@typescript/dtos/shared/id.dto';
import useNotification from '@hooks/useNotification';
import { useGetMyInactiveListingsQuery } from '@store/api/listingApi';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import { toRem } from '@utils/functions';
import { useMoralis } from 'react-moralis';
import useActionOrConnect from '@hooks/useActionOrConnect';

function MyListings() {
  const theme = useTheme();

  const { isWeb3Enabled } = useMoralis();

  const navigate = useNavigate();

  const down2Lg = useMediaQuery(theme.breakpoints.down('2lg'));

  // const { data: stressTest } = useGetStressTestQuery({});

  // console.log(stressTest);

  const handleActionOrConnect = useActionOrConnect();

  const { tab: urlTab } = useParams();

  const { deleteListing, sellWTB, updateWTB, updateWTS } = useListings();

  const {
    data: { count: inactiveListingsCount } = {
      count: 0,
    },
  } = useGetMyInactiveListingsQuery(
    paginationZod.parse({ page: 1, pageSize: 99 }),
  );

  const { userCoins } = useUser();

  const { notifyError, notifySuccess } = useNotification();

  const [tab, setTab] = useState<TMyListingsTab>({
    index: 0,
    name: MyListingRequestType.ACTIVE,
  });

  const [currentListing, setCurrentListing] = useState<TListing | null>(null);

  const [action, setAction] = useState<TDataActions>({
    listingAction: null,
    offerAction: null,
  });

  const [currentListingOffer, setCurrentListingOffer] = useState<
    TOffer | undefined
  >(undefined);

  const [actionModal, setActionModal] = useState<boolean>(false);

  const [tradeModalStep, setTradeModalStep] = useState<TradeModalStep>(
    TradeModalStep.BUY,
  );

  const closeActionModal = () => {
    setActionModal(false);
  };

  const handleOfferActions = (
    listing: TListing,
    offerAction: OfferAction,
    offer: TOffer,
  ) => {
    if (!isWeb3Enabled) {
      handleActionOrConnect();
    } else {
      setCurrentListingOffer(offer);
      setCurrentListing(listing);
      setAction({ offerAction, listingAction: null });
      setActionModal(true);
      setTradeModalStep(TradeModalStep.BUY);
    }
  };

  const handleListingActions = (
    listing: TListing,
    listingAction: ListingAction,
  ) => {
    if (!isWeb3Enabled) {
      handleActionOrConnect();
    } else {
      setCurrentListing(listing);
      setAction({ offerAction: null, listingAction });
      setActionModal(true);
      setTradeModalStep(TradeModalStep.BUY);
    }
  };

  const sellWTBListing = async (acceptedCoin: Coin) => {
    if (!currentListing) {
      throw Error('no current listing found in sellWTBListing');
    }
    try {
      await sellWTB(sellWTBZod.parse({ id: currentListing.id, acceptedCoin }));
      notifySuccess('Sell confirmation sent!');
    } catch (error) {
      notifyError('Sell confirmation failed!');
      throw error;
    }

    closeActionModal();
  };

  const listingsContent = () => {
    if (down2Lg) {
      return (
        <MyListingsTableResponsive
          tab={tab}
          handleOfferActions={handleOfferActions}
          handleListingActions={handleListingActions}
        />
      );
    }
    return (
      <MyListingsTable
        tab={tab}
        handleOfferActions={handleOfferActions}
        handleListingActions={handleListingActions}
      />
    );
  };

  const listingModals = () => {
    if (!currentListing || !isWeb3Enabled) {
      return null;
    }

    switch (action.listingAction) {
      case ListingAction.BUY_WTB:
        return (
          <WtbTxModals
            step={tradeModalStep}
            changeStep={(newStep: TradeModalStep) => setTradeModalStep(newStep)}
            listing={currentListing}
          />
        );

      case ListingAction.SELL_WTB:
        return (
          <SellWTBModal
            listing={currentListing}
            openModal={actionModal}
            closeModal={closeActionModal}
            sellWTBListing={sellWTBListing}
          />
        );

      case ListingAction.EDIT: {
        if (currentListing.type === ListingType.SELL) {
          return (
            <ModifyWTSListingModal
              openModal={actionModal}
              closeModal={closeActionModal}
              listing={currentListing}
              updateWTS={async (payload) => {
                await updateWTS(updateListingZod.parse(payload));
              }}
            />
          );
        }
        return (
          <ModifyWTBListingModal
            openModal={actionModal}
            closeModal={closeActionModal}
            listing={currentListing}
            updateWTB={async (payload) => {
              await updateWTB(updateListingZod.parse(payload));
            }}
            userCoins={userCoins}
          />
        );
      }

      case ListingAction.DELETE:
        return (
          <DeleteListingModal
            openModal={actionModal}
            closeModal={closeActionModal}
            listing={currentListing}
            deleteListing={async (payload) => {
              await deleteListing(idZod.parse(payload));
            }}
          />
        );
      default:
        return null;
    }
  };

  //! This actions is not possible in my listings. Should be removed.
  const offerModals = () => {
    if (currentListingOffer) {
      switch (action.offerAction) {
        case OfferAction.MAKE_OFFER_TRANSACTION:
          return (
            <OfferTxModals
              step={tradeModalStep}
              changeStep={(newStep: TradeModalStep) =>
                setTradeModalStep(newStep)
              }
              offer={currentListingOffer}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  useEffect(() => {
    if (urlTab) {
      const currentTab = urlTab.toUpperCase();

      if (urlTab && Object.keys(MyListingsContentType).includes(currentTab)) {
        switch (currentTab) {
          case 'INACTIVE':
            setTab({
              index: MyListingsContentType.INACTIVE,
              name: MyListingRequestType.INACTIVE,
            });
            break;
          default:
            setTab({
              index: MyListingsContentType.ACTIVE,
              name: MyListingRequestType.ACTIVE,
            });
            break;
        }
      }
    }
  }, [urlTab]);

  return (
    <MainBox sx={{ maxHeight: 'calc(100vh - 58px)', height: '100%' }}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: '1920px',
          margin: '0 auto',
        }}
      >
        <Box sx={{ marginTop: { xs: '0', lg: '1rem' } }}>
          <Typography
            variant="h5"
            sx={{ color: 'primary.main', display: { xs: 'none', lg: 'block' } }}
          >
            My Listings{' '}
            <Box component="span" sx={{ opacity: 0.5 }}>
              {'>'} {tab.name}
            </Box>
          </Typography>
          <TabContainer sx={{ mt: 1 }}>
            <TabBtn
              onClick={() => {
                setTab({
                  index: MyListingsContentType.ACTIVE,
                  name: MyListingRequestType.ACTIVE,
                });
                navigate(`/my-listings/active`);
              }}
              sx={
                tab.index === MyListingsContentType.ACTIVE ? activeTabStyle : {}
              }
            >
              Active
            </TabBtn>
            <TabBtn
              onClick={() => {
                setTab({
                  index: MyListingsContentType.INACTIVE,
                  name: MyListingRequestType.INACTIVE,
                });

                navigate(`/my-listings/inactive`);
              }}
              sx={
                tab.index === MyListingsContentType.INACTIVE
                  ? activeTabStyle
                  : {}
              }
            >
              Inactive{' '}
              <Box
                sx={{
                  marginLeft: toRem(4),
                }}
              >
                ( {inactiveListingsCount === 99 ? '99+' : inactiveListingsCount}{' '}
                )
              </Box>
            </TabBtn>
          </TabContainer>
        </Box>
        <Box sx={{ height: '100%' }}>{listingsContent()}</Box>
      </Box>
      {currentListing &&
        (action.listingAction ? listingModals() : offerModals())}
    </MainBox>
  );
}

export default MyListings;
