export const hubspotSearch = async (searchText: string) => {
  const hSearchUrl = 'https://api.hubapi.com/contentsearch/v2/search';
  const LVPortalID = 26278877;
  const domain = 'docs.legion.ventures';

  const hUrl = `${hSearchUrl}?portalId=${LVPortalID}&term=${searchText}&domain=${domain}`;
  const response = await fetch(hUrl);
  const data = await response.json();
  return data;
};
