import { z } from 'zod';
import { GetTradeTxType } from '@typescript/models/TradeTransaction.model';
import { paginationZod } from '../shared/pagination.dto';

export const getTradeTxByTypeZod = z
  .object({
    type: z.nativeEnum(GetTradeTxType).optional(),
  })
  .merge(paginationZod);

export const getTradeTxByProjectZod = z
  .object({
    id: z.string(),
  })
  .merge(paginationZod);

export type GetTradeTxByTypeDto = z.infer<typeof getTradeTxByTypeZod>;
export type GetTradeTxByProjectDto = z.infer<typeof getTradeTxByProjectZod>;
