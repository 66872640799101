import {
  Box,
  styled,
  TextField,
  Checkbox,
  Button,
  Typography,
  List,
  ListItem,
} from '@mui/material';
import { toRem } from '@utils/functions';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import customColors from '@theme/customColors';
import { border1pxPrimaryLight } from '@styles/shared/SCss';

export const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'transparent',
  padding: 0,
  borderRadius: toRem(8),
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.secondary.light,
  minWidth: 432,
  ':focus': {
    outline: 'none',
  },
  [theme.breakpoints.down('xsm')]: {
    width: '100%',
    top: 'auto',
    bottom: 0,
    minWidth: 'unset',
    transform: 'translate(-50%, 0%)',
  },
  '::before': {
    position: 'absolute',
    content: '""',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.secondary.dark,
    backdropFilter: 'blur(20px)',
    zIndex: -1,
    borderRadius: toRem(8),
    overflow: 'hidden',
  },
}));

export const HeaderModal = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.primary.light}`,
  padding: '15px',
}));

export const StepsIndicator = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  marginLeft: 10,
  fontSize: 13,
  color: theme.palette.text.secondary,
  padding: '2px 6px',
  borderRadius: 4,
  border: `1px solid ${theme.palette.text.secondary}`,
}));

export const ModalH6Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: 13,
  color: theme.palette.text.secondary,
}));

export const ModalH4Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  fontSize: 15,
  color: theme.palette.text.secondary,
  lineHeight: 'unset',
}));

export const CurrentPriceBox = styled(Box)(() => ({
  '& .MuiTypography-root': {
    fontWeight: 400,
    paddingTop: '10px',
    fontSize: 15,
    '& .MuiBox-root': {
      height: '15px',
      lineHeight: 1,
      fontSize: 10,
      display: 'flex',
      alignItems: 'flex-end',
      marginTop: '3px',
    },
  },
}));

export const QuestionMarkStyled = styled(QuestionMarkIcon)(({ theme }) => ({
  backgroundColor: theme.palette.text.secondary,
  borderRadius: '4px',
  padding: '0.1rem',
  color: theme.palette.text.primary,
  width: '14px',
  height: '14px',
}));

export const CloseBtn = styled(Button)(({ theme }) => ({
  alignSelf: 'flex-end',
  padding: '0.1rem',
  minWidth: 'unset',
  position: 'absolute',
  top: '50%',
  right: '15px',
  transform: 'translateY(-50%)',
  borderRadius: '4px',
  color: theme.palette.text.secondary,
  ':hover': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export const FieldStyled = styled(TextField)(({ theme }) => ({
  borderRadius: '6px',
  '& .MuiOutlinedInput-root.Mui-error': {
    borderColor: theme.palette.error.main,
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.secondary.light}`,
    backgroundColor: 'transparent',
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent',
    paddingInline: '0.5rem',
    color: theme.palette.text.secondary,
    '&::before': {
      position: 'absolute',
      top: '50%',
      left: 0,
      width: '100%',
      height: '3px',
      content: '""',
      backgroundColor: customColors.cardGrey,
      zIndex: -1,
    },
  },
  '& .MuiInputLabel-outlined.Mui-error': {
    color: theme.palette.error.main,
  },
  '& .MuiFormHelperText-root': {
    marginInline: 0,
    padding: 0,
    paddingLeft: '1rem',
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      opacity: 1,
      color: theme.palette.text.primary,
    },
  },
}));

export const CheckBoxStyled = styled(Checkbox)(({ theme, checked }) => ({
  borderRadius: '4px',
  padding: 0,
  margin: 0,
  border: `1px solid ${theme.palette.text.secondary}`,
  backgroundColor: 'transparent',
  '& .MuiSvgIcon-root': {
    fill: checked ? 'white' : 'none',
    width: 20,
    height: 20,
  },
}));

export const ModalFooter = styled(Box)(() => ({
  padding: '15px',
  borderTop: border1pxPrimaryLight,
}));

export const ModalDetailsBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  border: border1pxPrimaryLight,
}));

export const ExpireBox = styled(Box)(() => ({
  marginTop: '13px',
  borderRadius: '4px',
  border: border1pxPrimaryLight,
  display: 'flex',
}));

export const ExpireInDaysButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: '7px 21px',
  borderRadius: 0,
  ':hover': {
    background: customColors.midnightSeaRadialGradientOpacity50,
    backgroundSize: '170%',
    backgroundPosition: '-46px -42px',
  },
}));

export const ExpireInText = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRight: border1pxPrimaryLight,
  borderLeft: border1pxPrimaryLight,
  width: '100%',
  fontSize: '15px',
  lineHeight: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ErrorListTooltip = styled(List)(({ theme }) => ({
  position: 'absolute',
  border: `1px solid ${theme.palette.error.main}`,
  padding: '10px',
  borderRadius: '8px',
  left: '50%',
  top: 'calc(100% + 20px)',
  transform: 'translateX(-50%)',
  overflow: 'hidden',
  ':before': {
    position: 'absolute',
    backdropFilter: 'blur(14px)',
    content: '""',
    width: '100%',
    height: '100%',
    backgroundColor: customColors.darkBlue70,
    zIndex: -1,
    top: 0,
    left: 0,
  },
  [theme.breakpoints.down('md')]: {
    top: 'auto',
    bottom: 'calc(100% + 20px)',
  },
}));

export const ErrorListItemTooltip = styled(ListItem)(({ theme }) => ({
  color: theme.palette.error.main,
  padding: 0,
  fontSize: 12,
}));
