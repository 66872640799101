import { Box, TextField, styled } from '@mui/material';
import customColors from '@theme/customColors';

export const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '14px',
  right: '14px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '6px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.secondary.light,
  width: 330,
  height: 580,
  ':focus': {
    outline: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 28px)',
    transform: 'translateX(-50%, -50%)',
  },
}));

export const CardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '6px',
  justifyContent: 'space-between',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const SearchBar = styled(TextField)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent !important',
  },
  '& .MuiInputBase-input': {
    color: customColors.boxColor,
    padding: '0.5rem !important',
  },
}));
