import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import Moralis from 'moralis-v1';
import { CreateOfferDTO } from '@typescript/dtos/offer/createOffer.dto';
import {
  UpdateOfferDTO,
  SetStatusOfferDTO,
} from '@typescript/dtos/offer/updateOffer.dto';
import { IdDTO, NameDTO } from '@typescript/dtos/shared/id.dto';
import {
  PaginationDTO,
  resourceWithPaginatedZod,
} from '@typescript/dtos/shared/pagination.dto';
import { offerZod } from '@typescript/models/Offer.model';

export const offerApi = createApi({
  reducerPath: ApiSlice.OFFERS,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.OFFERS],
  endpoints: (builder) => ({
    getOffers: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_OFFERS,
            payload,
          );
          return { data: resourceWithPaginatedZod(offerZod).parse(response) };
        } catch (error) {
          throw Error(`getOffers: ${error}`);
        }
      },
      providesTags: [ApiTag.OFFERS],
    }),
    getMyOffers: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_OFFERS,
            payload,
          );
          return { data: resourceWithPaginatedZod(offerZod).parse(response) };
        } catch (error) {
          throw Error(`getMyOffers: ${error}`);
        }
      },
      providesTags: [ApiTag.OFFERS],
    }),
    getMyOffersWithTx: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_OFFERS_WITH_TX,
            payload,
          );
          return { data: resourceWithPaginatedZod(offerZod).parse(response) };
        } catch (error) {
          throw Error(`getMyOffersWithTx: ${error}`);
        }
      },
      providesTags: [ApiTag.OFFERS],
    }),
    getMyReceivedOffers: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_RECEIVED_OFFERS,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(offerZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyReceivedOffers: ${error}`);
        }
      },
      providesTags: [ApiTag.OFFERS],
    }),
    getMyInactiveOffers: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_INACTIVE_OFFERS,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(offerZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyInactiveOffers: ${error}`);
        }
      },
      providesTags: [ApiTag.OFFERS],
    }),
    getMyActiveOffers: builder.query({
      async queryFn(payload?: PaginationDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_MY_ACTIVE_OFFERS,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(offerZod).parse(response),
          };
        } catch (error) {
          throw Error(`getMyActiveOffers: ${error}`);
        }
      },
      providesTags: [ApiTag.OFFERS],
    }),
    createOffer: builder.mutation({
      async queryFn(dto: CreateOfferDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_OFFER,
            dto,
          );
          return { data: offerZod.parse(response) };
        } catch (error) {
          throw Error(`createOffer: ${error}`);
        }
      },
    }),
    updateOffer: builder.mutation({
      async queryFn(dto: UpdateOfferDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_OFFER,
            dto,
          );
          return { data: offerZod.parse(response) };
        } catch (error) {
          throw Error(`updateOffer: ${error}`);
        }
      },
    }),
    updateOfferStatus: builder.mutation({
      async queryFn(dto: SetStatusOfferDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_OFFER_STATUS,
            dto,
          );

          return { data: offerZod.parse(response) };
        } catch (error) {
          throw Error(`updateOfferStatus: ${error}`);
        }
      },
    }),
    deleteOffer: builder.mutation({
      async queryFn(dto: IdDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.DELETE_OFFER,
            dto,
          );
          return { data: response };
        } catch (error) {
          throw Error(`deleteOffer: ${error}`);
        }
      },
    }),
    searchOffers: builder.query({
      async queryFn(payload: NameDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.SEARCH_OFFERS,
            payload,
          );
          return { data: offerZod.array().parse(response) };
        } catch (error) {
          throw Error(`getMyOffers: ${error}`);
        }
      },
      providesTags: [ApiTag.OFFERS],
    }),
  }),
});

export const {
  useCreateOfferMutation,
  useDeleteOfferMutation,
  useUpdateOfferMutation,
  useGetMyInactiveOffersQuery,
  useGetMyActiveOffersQuery,
  useUpdateOfferStatusMutation,
  useLazySearchOffersQuery,
} = offerApi;
