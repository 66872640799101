import Account from '@pages/Account';
import Portfolio from '@pages/Portfolio';
import Dashboard from '@pages/Dashboard';
import DefaultPage from '@pages/Default';
import Explore from '@pages/Explore';
import Projects from '@pages/ProjectDeals';
import ProjectSinglePage from '@pages/Project';
import MyListings from '@pages/MyListings';
import Offers from '@pages/Offers';
import ActivityStream from '@pages/ActivityStream';
import ProjectCreate from '@pages/ProjectCreate';
import AdminPanel from '@pages/AdminPanel';

export type RouteType = {
  path: string;
  Component: () => JSX.Element;
  showNavbar: boolean;
  isProjected?: boolean;
};

const routes: RouteType[] = [
  {
    path: '/',
    Component: Dashboard,
    showNavbar: true,
    isProjected: true,
  },
  {
    path: '/explore',
    Component: Explore,
    showNavbar: true,
  },
  {
    path: '/portfolio',
    Component: Portfolio,
    showNavbar: true,
    isProjected: true,
  },
  {
    path: '/portfolio/:tab',
    Component: Portfolio,
    showNavbar: true,
    isProjected: true,
  },
  {
    path: '/projects',
    Component: Projects,
    showNavbar: true,
  },
  {
    path: '/projects/:id',
    Component: ProjectSinglePage,
    showNavbar: false,
  },

  {
    path: '/project-create',
    Component: ProjectCreate,
    showNavbar: false,
    isProjected: true,
  },

  // TODO: create projects
  // {
  //   path: '/explore/create',
  //   component: ProjectCreate,
  //   name: 'Projects Create',
  //   showNavbar: false,
  // },
  {
    path: '/account',
    Component: Account,
    showNavbar: true,
    isProjected: true,
  },
  {
    path: '/activity-stream',
    Component: ActivityStream,
    showNavbar: true,
    isProjected: true,
  },
  {
    path: '/activity-stream/:tab',
    Component: ActivityStream,
    showNavbar: true,
    isProjected: true,
  },
  {
    path: '/my-listings',
    Component: MyListings,
    showNavbar: false,
    isProjected: true,
  },
  {
    path: '/my-listings/:tab',
    Component: MyListings,
    showNavbar: false,
    isProjected: true,
  },
  {
    path: '/offers',
    Component: Offers,
    showNavbar: false,
    isProjected: true,
  },
  {
    path: '/offers/:tab',
    Component: Offers,
    showNavbar: false,
    isProjected: true,
  },
  {
    path: '/admin-panel',
    Component: AdminPanel,
    showNavbar: false,
    isProjected: true,
  },
  {
    path: '*',
    Component: () => DefaultPage({ fullHeight: true }),
    showNavbar: false,
  },
];

export default routes;
