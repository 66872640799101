import { z } from 'zod';

export const updateUserInfoZod = z.object({
  id: z.string(),
  email: z.string().optional().nullable(),
  isEmailVerified: z.boolean(),
  emailVerificationCode: z.number().optional().nullable(),
  discordName: z.string().optional().nullable(),
  telegramLink: z.string().optional().nullable(),
});

export type UpdateUserInfoDTO = z.infer<typeof updateUserInfoZod>;
