import { Box, Divider, Typography, styled } from '@mui/material';
import { border1pxPrimaryLight } from '@styles/shared/SCss';
import { toRem } from '@utils/functions';

export const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: `${theme.palette.secondary.main}`,
  borderRadius: toRem(10),
  border: border1pxPrimaryLight,
  overflow: 'hidden',
}));

export const ProjectName = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  marginLeft: '0.5rem',
  marginRight: '6px',
  fontSize: '15px',
}));

export const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${toRem(10)} ${toRem(10)} 0 ${toRem(10)}`,
  ':hover': {
    cursor: 'pointer',
  },
}));

export const CardH6Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  color: theme.palette.text.secondary,
  marginRight: '0.3rem',
}));

export const ProgressDetailsBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '11px 10px 10px',
  borderBottom: border1pxPrimaryLight,
}));

export const ProgressDetailsDivider = styled(Divider)(({ theme }) => ({
  position: 'relative',
  top: 'auto',
  left: 'auto',
  height: '15px',
  width: '1px',
  backgroundColor: theme.palette.primary.light,
  border: 'none',
}));

export const CurrentPriceBox = styled(Box)(() => ({
  '& .MuiTypography-root': {
    fontWeight: 500,
    fontSize: 15,
    '& .MuiBox-root': {
      height: '15px',
      lineHeight: 1,
      fontSize: 10,
      display: 'flex',
      alignItems: 'flex-end',
      marginTop: '0px',
    },
  },
}));
