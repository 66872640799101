import { Coin } from '@constants/CCoin';
import { Day } from './models/Listing.model';

export interface TCreateWTSListingInitialValues {
  tokenPrice: string;
  tokenAmount: string;
  totalCost: string;
  coins: Coin[];
  expireIn: Day;
  negotiable: boolean;
  acceptTerms: boolean;
  discordName?: string | null;
  telegramLink?: string | null;
}

export interface TCreateWTBListingInitialValues {
  tokenPrice: string;
  tokenAmount: string;
  totalCost: string;
  coins: Coin[];
  expireIn: Day;
  acceptTerms: boolean;
  discordName?: string | null;
  telegramLink?: string | null;
}

export interface TUpdateListingExpirationInitialValues {
  expireIn: Day;
}

export interface TBuyConfirmationInterface {
  selectedCoin: Coin | '';
  coinValue: number;
}

export interface TSellConfirmationInterface {
  amount: number;
  selectedCoin: Coin | '';
  coinValue: number;
}

export interface TMakeOfferInitialValues {
  selectedCoin: Coin | '';
  offeredPrice: string;
  coinValue: number;
  offeredTokenPrice: string;
}

export interface TCreateWTIListingInitialValues {
  totalCost: string;
  tokenAmount: string;
  coin: Coin;
  acceptTerms: boolean;
}

export interface TCreateInvestmentEthProjectInitialValues {
  totalCost: string;
  acceptTerms: boolean;
}

export enum EthInvestmentModalStep {
  STEP_1,
  STEP_2,
  SUCCESS,
  ERROR,
  LOADING,
  APPROVE_TX,
  TX_SEND,
  SWITCH_CHAIN,
}
