import { OTC_TRANSFER_FEE_PERCENTAGE } from '@constants/COtcTransferContract';
import { calculate } from './functions';

export const calculateAmountAfterFee = (listingPrice: number) => {
  const feeOfListingPrice = calculate(
    OTC_TRANSFER_FEE_PERCENTAGE,
    '%',
    listingPrice,
  );
  const totalPrice = calculate(listingPrice, '+', feeOfListingPrice);
  return {
    totalPrice,
    feeOfListingPrice,
  };
};
