import { CloudFunctions } from '@constants/CCloudFunctions';
import { TransferType } from '@constants/CTransfer';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import { ServerNetwork } from '@utils/functions';
import Moralis from 'moralis-v1';

import { transactionApi } from './transactionApi';
import { transactionInvestmentApi } from './transactionInvestmentApi';

export const utilApi = createApi({
  reducerPath: ApiSlice.UTILS,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.UTILS],
  endpoints: (builder) => ({
    getTransferType: builder.query({
      async queryFn() {
        try {
          const response: TransferType = await Moralis.Cloud.run(
            CloudFunctions.GET_TRANSFER_TYPE,
          );
          return { data: response };
        } catch (error) {
          throw Error(`getTransferType: ${error}`);
        }
      },
      providesTags: [ApiTag.UTILS],
    }),
    getServerStatus: builder.query({
      async queryFn() {
        const response = await Moralis.Cloud.run(
          CloudFunctions.GET_SERVER_STATUS,
        ).catch(async (error) => {
          localStorage.clear();

          throw error;
        });
        return { data: response };
      },
      providesTags: [ApiTag.UTILS],
    }),
    getServerNetwork: builder.query({
      async queryFn() {
        try {
          const response: ServerNetwork = await Moralis.Cloud.run(
            CloudFunctions.GET_SERVER_NETWORK,
          );
          return { data: response };
        } catch (error) {
          throw Error(`getServerNetwork: ${error}`);
        }
      },
      providesTags: [ApiTag.UTILS],
    }),
    insertAllUserFailedTransactions: builder.mutation({
      async queryFn() {
        try {
          await Moralis.Cloud.run(
            CloudFunctions.INSERT_ALL_USER_FAILED_TRANSACTIONS,
          );
          return { data: null };
        } catch (error) {
          throw Error(`insertAllUserFailedTransactions Error: ${error}`);
        }
      },
      onQueryStarted: (_, api) => {
        api.dispatch(transactionApi.util.invalidateTags([ApiTag.TRANSACTIONS]));
        api.dispatch(
          transactionInvestmentApi.util.invalidateTags([
            ApiTag.TRANSACTIONS_INVESTMENT,
          ]),
        );
      },
    }),
    getStressTest: builder.query({
      async queryFn() {
        try {
          const response = await Moralis.Cloud.run(CloudFunctions.STRESS_TEST);
          return { data: response };
        } catch (error) {
          throw Error(`getServerNetwork: ${error}`);
        }
      },
      providesTags: [ApiTag.UTILS],
    }),

  }),
});

export const {
  useGetTransferTypeQuery,
  useGetServerNetworkQuery,
  useInsertAllUserFailedTransactionsMutation,
} = utilApi;
