import { z } from 'zod';
import { userBalanceZod } from '@typescript/dtos/shared/coin.dto';
import { userInfoZod } from './UserInformation.model';

export const userZod = z.object({
  id: z.string(),
  username: z.string(),
  accounts: z.string().array(),
  ethAddress: z.string(),
  info: userInfoZod,
  profileImage: z.string().optional().nullable(),
});

export const currentUserZod = z
  .object({
    balance: userBalanceZod.array(),
  })
  .merge(userZod);

// eslint-disable-next-line import/no-unused-modules
export type TUser = z.infer<typeof userZod>;

export type UserCoin = z.infer<typeof userBalanceZod>;
export type CurrentUser = z.infer<typeof currentUserZod>;
