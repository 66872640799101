import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { TAllocation } from '@typescript/models/Allocation.model';
import useListings from '@hooks/useListings';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MainBoxMargin } from '@styles/shared/SCss';
import { WTSListingInitialValues } from '@formik/modal/ModalInitialData';
import { WTSListingValidationSchema } from '@formik/modal/ModalValidation';
import WtsStep2Modal from '@components/modals/listings/WtsStep2Modal';
import WtsStep1Modal from '@components/modals/listings/WtsStep1Modal';
import SummaryHeader from '@components/shared/SummaryHeader';
import { TCreateWTSListingInitialValues } from '@typescript/TModalFormik';
import { Day } from '@typescript/models/Listing.model';
import { createListingZod } from '@typescript/dtos/listing/createListing.dto';
import useNotification from '@hooks/useNotification';
import AllocationsView from '@components/portfolio/AllocationsView';
import EthAllocationsView from '@components/portfolio/EthAllocationsView';
import { PortfolioContentType } from '@constants/CContentType';
import { useNavigate, useParams } from 'react-router-dom';
import { TabContainer } from '@styles/shared/SBoxes';
import { TabBtn, activeTabStyle } from '@styles/shared/SButtons';

function Portfolio() {
  const { tab: urlTab } = useParams();

  const navigate = useNavigate();

  const { notifyError, notifySuccess } = useNotification();

  const { createWTS } = useListings();

  const [openStep1Modal, setOpenStep1Modal] = useState<boolean>(false);

  const [openStep2Modal, setOpenStep2Modal] = useState<boolean>(false);

  const [steps, setSteps] = useState<1 | 2>(1);

  const [currentAllocation, setCurrentAllocation] = useState<TAllocation>();

  const [activeTab, setActiveTab] = useState<PortfolioContentType>(
    PortfolioContentType.ALLOCATIONS,
  );

  const onOpenModal = (allocation: TAllocation) => {
    setCurrentAllocation(allocation);
    setOpenStep1Modal(true);
    setSteps(1);
  };

  const changeSteps = (number: 1 | 2) => {
    setSteps(number);
  };

  const handleOnSubmitFormik = async (
    values: TCreateWTSListingInitialValues,
  ) => {
    try {
      if (currentAllocation) {
        await createWTS(
          createListingZod.parse({
            amount: +values.tokenAmount,
            coins: values.coins,
            negotiable: values.negotiable,
            price: +values.totalCost,
            tokenPrice: +values.tokenPrice,
            projectId: currentAllocation.project.id,
            expireIn: +values.expireIn as Day,
            discordName: values.discordName,
            telegramLink: values.telegramLink,
          }),
        ).unwrap();
        notifySuccess('Listing created!');
        setOpenStep1Modal(false);
        setOpenStep2Modal(false);
      }
    } catch (error) {
      setOpenStep1Modal(false);
      setOpenStep2Modal(false);
      notifyError('Listing creation failed!');
      throw error;
    }
  };

  const contentControl = () => {
    switch (true) {
      case activeTab === PortfolioContentType.ETH_ALLOCATIONS:
        return <EthAllocationsView />;
      default:
        return <AllocationsView onOpenModal={onOpenModal} />;
    }
  };

  useEffect(() => {
    if (!urlTab) {
      navigate(`/portfolio/${PortfolioContentType.ALLOCATIONS}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tab = urlTab?.toLowerCase() as PortfolioContentType | undefined;

    if (tab && Object.values(PortfolioContentType).includes(tab)) {
      setActiveTab(urlTab as PortfolioContentType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTab]);

  return (
    <Box sx={{ marginBlock: { xs: '68px', lg: MainBoxMargin } }}>
      <SummaryHeader />
      <Box sx={{ maxWidth: '1200px', marginInline: 'auto' }}>
        <TabContainer sx={{ m: 2 }}>
          <TabBtn
            onClick={() => {
              setActiveTab(PortfolioContentType.ALLOCATIONS);
              navigate(`/portfolio/${PortfolioContentType.ALLOCATIONS}`);
            }}
            sx={
              activeTab === PortfolioContentType.ALLOCATIONS
                ? activeTabStyle
                : {}
            }
          >
            Allocations
          </TabBtn>
          <TabBtn
            onClick={() => {
              setActiveTab(PortfolioContentType.ETH_ALLOCATIONS);
              navigate(`/portfolio/${PortfolioContentType.ETH_ALLOCATIONS}`);
            }}
            sx={
              activeTab === PortfolioContentType.ETH_ALLOCATIONS
                ? activeTabStyle
                : {}
            }
          >
            Legion Allocations
          </TabBtn>
        </TabContainer>
        <Box sx={{ width: '100%' }}>{contentControl()}</Box>
      </Box>
      {currentAllocation && (
        <Formik
          initialValues={WTSListingInitialValues}
          validate={(values) => {
            try {
              validateYupSchema(
                values,
                WTSListingValidationSchema(
                  currentAllocation.project.currentPrice,
                  currentAllocation.lockedTokens,
                ),
                true,
                values,
              );
            } catch (err) {
              return yupToFormErrors(err);
            }

            return {};
          }}
          onSubmit={(values) => handleOnSubmitFormik(values)}
        >
          <>
            {steps === 1 && openStep1Modal && currentAllocation && (
              <WtsStep1Modal
                openModal={openStep1Modal}
                closeModal={() => setOpenStep1Modal(false)}
                changeSteps={changeSteps}
                openWtsStep2Modal={() => setOpenStep2Modal(true)}
                project={currentAllocation.project}
              />
            )}
            {steps === 2 && openStep2Modal && currentAllocation && (
              <WtsStep2Modal
                openModal={openStep2Modal}
                closeModal={() => setOpenStep2Modal(false)}
                openWtsStep1Modal={() => setOpenStep1Modal(true)}
                changeSteps={changeSteps}
                project={currentAllocation.project}
              />
            )}
          </>
        </Formik>
      )}
    </Box>
  );
}

export default Portfolio;
