import ConfirmationOfferModal from '@components/modals/offers/ConfirmationOfferModal';
import DeleteOfferModal from '@components/modals/offers/DeleteOfferModal';
import ModifyOfferModal from '@components/modals/offers/ModifyOfferModal';
import OfferTxModals from '@components/modals/transactions/OfferTxModals';
import OffersTable from '@components/offers/OffersTable';
import OffersTableResponsive from '@components/offers/responsive/OffersTableResponsive';
import {
  ActivityStreamContentType,
  MyListingsContentType,
  OffersContentType,
} from '@constants/CContentType';
import { OfferAction } from '@constants/CDataActions';
import { OfferStatus } from '@constants/COffer';
import { TradeModalStep } from '@constants/CTransfer';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { MainBox, TabContainer } from '@styles/shared/SBoxes';
import { TabBtn, activeTabStyle } from '@styles/shared/SButtons';
import { TOffer } from '@typescript/models/Offer.model';
import { TOffersTab } from '@typescript/TTabs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMoralis } from 'react-moralis';
import useActionOrConnect from '@hooks/useActionOrConnect';

function Offers() {
  const theme = useTheme();

  const down2Lg = useMediaQuery(theme.breakpoints.down('2lg'));
  const { isWeb3Enabled } = useMoralis();
  const handleActionOrConnect = useActionOrConnect();

  const navigate = useNavigate();

  const { tab: urlTab } = useParams();

  const [tradeModalStep, setTradeModalStep] = useState<TradeModalStep>(
    TradeModalStep.BUY,
  );

  const [currentOffer, setCurrentOffer] = useState<TOffer | null>(null);

  const [action, setAction] = useState<OfferAction | null>(null);

  const [actionModal, setActionModal] = useState<boolean>(false);

  const [tab, setTab] = useState<TOffersTab>({
    index: 0,
    name: 'Active',
  });

  const closeActionModal = () => {
    setActionModal(false);
    setCurrentOffer(null);
  };

  const changeAction = (newOffer: TOffer, newAction: OfferAction) => {
    if (!isWeb3Enabled) {
      handleActionOrConnect();
    } else {
      setCurrentOffer(newOffer);
      setAction(newAction);
      setActionModal(true);
      setTradeModalStep(TradeModalStep.BUY);
    }
  };

  const offersContent = () => {
    if (down2Lg) {
      return <OffersTableResponsive tab={tab} changeAction={changeAction} />;
    }
    return <OffersTable tab={tab} changeAction={changeAction} />;
  };

  const offerModals = () => {
    if (currentOffer) {
      switch (action) {
        case OfferAction.MAKE_OFFER_TRANSACTION:
          return (
            <OfferTxModals
              step={tradeModalStep}
              changeStep={(newStep: TradeModalStep) =>
                setTradeModalStep(newStep)
              }
              offer={currentOffer}
            />
          );
        case OfferAction.DELETE:
          return (
            <DeleteOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              currentOffer={currentOffer}
            />
          );
        case OfferAction.EDIT:
          return (
            <ModifyOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              offer={currentOffer}
            />
          );
        case OfferAction.ACCEPT_OFFER:
          return (
            <ConfirmationOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              currentOffer={currentOffer}
              newStatus={OfferStatus.ACCEPTED}
            />
          );
        case OfferAction.REJECT_OFFER:
          return (
            <ConfirmationOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              currentOffer={currentOffer}
              newStatus={OfferStatus.REJECTED}
            />
          );

        default:
          return null;
      }
    }
    return null;
  };

  useEffect(() => {
    if (urlTab && Object.values(ActivityStreamContentType).includes(+urlTab)) {
      switch (+urlTab) {
        case MyListingsContentType.INACTIVE:
          setTab({
            index: OffersContentType.INACTIVE,
            name: 'Inactive',
          });
          break;
        default:
          setTab({
            index: OffersContentType.ACTIVE,
            name: 'Active',
          });
          break;
      }
    }
  }, [urlTab]);

  return (
    <MainBox sx={{ maxHeight: 'calc(100vh - 58px)', height: '100%' }}>
      <Box
        sx={{
          maxWidth: '1920px',
          margin: '0 auto',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            marginTop: { xs: '0', lg: '1rem' },
            backgroundColor: 'transparent',
            position: { xs: 'sticky', '2lg': 'relative' },
            top: 0,
            backdropFilter: 'blur(25px)',
            zIndex: '10',
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: 'primary.main', display: { xs: 'none', lg: 'block' } }}
          >
            Offers{' '}
            <Box component="span" sx={{ opacity: 0.5 }}>
              {'>'} {tab.name}
            </Box>
          </Typography>
          <TabContainer sx={{ mt: 1 }}>
            <TabBtn
              onClick={() => navigate('/offers/0')}
              sx={tab.index === OffersContentType.ACTIVE ? activeTabStyle : {}}
            >
              Active
            </TabBtn>
            <TabBtn
              onClick={() => navigate('/offers/1')}
              sx={
                tab.index === OffersContentType.INACTIVE ? activeTabStyle : {}
              }
            >
              Inactive
            </TabBtn>
          </TabContainer>
        </Box>
        {offersContent()}
      </Box>
      {offerModals()}
    </MainBox>
  );
}

export default Offers;
