import { Coin } from '@constants/CCoin';
import { z } from 'zod';

export const createListingZod = z.object({
  price: z.number(),
  coins: z.nativeEnum(Coin).array(),
  expireIn: z.number(),
  tokenPrice: z.number(),
  projectId: z.string(),
  amount: z.number(),
  negotiable: z.boolean().default(false),
  discordName: z.string().optional(),
  telegramLink: z.string().optional(),
});

export const sellWTBZod = z.object({
  id: z.string(),
  acceptedCoin: z.nativeEnum(Coin),
});

export type CreateListingDTO = z.infer<typeof createListingZod>;
export type SellWtbDTO = z.infer<typeof sellWTBZod>;
