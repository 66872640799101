import { Coin } from '@constants/CCoin';
import { TradeType } from '@typescript/models/TradeTransaction.model';
import { z } from 'zod';

export const createTransactionZod = z.object({
  value: z.number(),
  fromId: z.string(),
  toId: z.string(),
  listingId: z.string(),
  tradeType: z.nativeEnum(TradeType),
  coin: z.nativeEnum(Coin),
  hash: z.string(),
});

export type CreateTransactionDTO = z.infer<typeof createTransactionZod>;
