/* eslint-disable import/no-unused-modules */
import { CloudFunctions } from '@constants/CCloudFunctions';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiSlice, ApiTag } from '@typescript/Api.enum';
import Moralis from 'moralis-v1';
import { feeZod } from '@typescript/models/Fee.model';
import { tokenDistributionZod } from '@typescript/models/TokenDistribution.model';
import { IdDTO, NameDTO } from '@typescript/dtos/shared/id.dto';
import { CreateProjectPayloadDTO } from '@typescript/dtos/project/createProject.dto';
import { TExcelProject, TExcelAllocation, TExcelFee } from '@typescript/TExcel';
import { resourceWithPaginatedZod } from '@typescript/dtos/shared/pagination.dto';
import {
  RecalculateExcelProjectDto,
  UpdateProjectDto,
} from '@typescript/dtos/project/updateProject.dto';
import { projectZod } from '@typescript/models/Project.model';
import { GetProjectsByTypeDTO } from '@typescript/dtos/project/getProject.dto';

interface CreateExcelProject {
  project: TExcelProject;
  allocations: TExcelAllocation[];
  fee: TExcelFee;
}

export const projectApi = createApi({
  reducerPath: ApiSlice.PROJECTS,
  baseQuery: fetchBaseQuery(),
  tagTypes: [ApiTag.PROJECTS, ApiTag.FEE, ApiTag.TOKEN_DISTRIBUTIONS],
  endpoints: (builder) => ({
    getProjects: builder.query({
      async queryFn(payload?: GetProjectsByTypeDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_PROJECTS,
            payload,
          );
          return {
            data: resourceWithPaginatedZod(projectZod).parse(response),
          };
        } catch (error) {
          throw Error(`getProjects: ${error}`);
        }
      },
      providesTags: [ApiTag.PROJECTS],
    }),
    getProjectById: builder.query({
      async queryFn(payload: IdDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_PROJECT_BY_ID,
            payload,
          );
          return { data: projectZod.parse(response) };
        } catch (error) {
          throw Error(`getProjectById: ${error}`);
        }
      },
      providesTags: [ApiTag.PROJECTS],
    }),
    getProjectByName: builder.query({
      async queryFn(payload: NameDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_PROJECT_BY_NAME,
            payload,
          );
          return { data: projectZod.parse(response) };
        } catch (error) {
          throw Error(`getProjectByName: ${error}`);
        }
      },
      providesTags: [ApiTag.PROJECTS],
    }),
    getProjectBySlug: builder.query({
      async queryFn(payload: NameDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_PROJECT_BY_SLUG,
            payload,
          );
          return { data: projectZod.parse(response) };
        } catch (error) {
          throw Error(`getProjectBySlug: ${error}`);
        }
      },
      providesTags: [ApiTag.PROJECTS],
    }),
    createProject: builder.mutation({
      async queryFn(payload: CreateProjectPayloadDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_PROJECT,
            payload,
          );
          return { data: projectZod.parse(response) };
        } catch (error) {
          throw Error(`createProject: ${error}`);
        }
      },
    }),
    createExcelProject: builder.mutation({
      async queryFn(payload: CreateExcelProject) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.CREATE_EXCEL_PROJECT,
            payload,
          );
          return { data: projectZod.parse(response) };
        } catch (error) {
          throw Error(`createExcelProject: ${error}`);
        }
      },
    }),
    deleteProject: builder.mutation({
      async queryFn(payload: IdDTO) {
        try {
          await Moralis.Cloud.run(CloudFunctions.DELETE_PROJECT, payload);
          return { data: 'successfully deleted' };
        } catch (error) {
          throw Error(`deleteProject: ${error}`);
        }
      },
    }),

    deleteAllProjects: builder.mutation({
      async queryFn() {
        try {
          await Moralis.Cloud.run(CloudFunctions.DELETE_ALL_PROJECTS);
          return { data: 'successfully deleted all projects' };
        } catch (error) {
          throw Error(`deleteAllProjects: ${error}`);
        }
      },
    }),
    updateProject: builder.mutation({
      async queryFn(payload: UpdateProjectDto) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.UPDATE_PROJECT,
            payload,
          );
          return { data: projectZod.parse(response) };
        } catch (error) {
          throw Error(`updateProject: ${error}`);
        }
      },
    }),

    recalculateExcelProject: builder.mutation({
      async queryFn(payload: RecalculateExcelProjectDto) {
        try {
          await Moralis.Cloud.run(
            CloudFunctions.RECALCULATE_EXCEL_PROJECT,
            payload,
          );
          return { data: undefined };
        } catch (error) {
          throw Error(`recalculateExcelProject error: ${error}`);
        }
      },
    }),

    getProjectDistribution: builder.query({
      async queryFn() {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.GET_TOKEN_DISTRIBUTIONS,
          );
          return { data: tokenDistributionZod.array().parse(response) };
        } catch (error) {
          throw Error(`getProjectDistribution: ${error}`);
        }
      },
      providesTags: [ApiTag.TOKEN_DISTRIBUTIONS],
    }),
    getProjectFee: builder.query({
      async queryFn() {
        try {
          const response = await Moralis.Cloud.run(CloudFunctions.GET_FEES);
          return {
            data: feeZod.array().parse(response),
          };
        } catch (error) {
          throw Error(`getProjectFee: ${error}`);
        }
      },
      providesTags: [ApiTag.FEE],
    }),
    searchProjects: builder.query({
      async queryFn(payload: NameDTO) {
        try {
          const response = await Moralis.Cloud.run(
            CloudFunctions.SEARCH_PROJECT,
            payload,
          );
          return {
            data: projectZod.array().parse(response),
          };
        } catch (error) {
          throw Error(`getSearchProjects: ${error}`);
        }
      },
      providesTags: [ApiTag.PROJECTS],
    }),
  }),

});

export const {
  useGetProjectByNameQuery,
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useGetProjectByIdQuery,
  useDeleteAllProjectsMutation,
  useGetProjectDistributionQuery,
  useLazyGetProjectDistributionQuery,
  useGetProjectFeeQuery,
  useCreateProjectMutation,
  useCreateExcelProjectMutation,
  useLazySearchProjectsQuery,
  useGetProjectBySlugQuery,
  useRecalculateExcelProjectMutation,
} = projectApi;
