import { Box, Modal, Slide, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Svgs } from '@utils/svgs';
import useStyles from '@hooks/useStyles';
import { hubspotSearch } from '@utils/hubspot';
import { useEffect, useState } from 'react';
import SearchResults from '@components/help-center/HelpCenterSearchResults';
import LinkBox from '@components/help-center/LinkBox';
import { ModalContainer, SearchBar } from '@styles/help-center/SHelpCenter';
import { CloseBtn, HeaderModal } from '@styles/modal/SModal';
import { THelpCenterSearchResult } from '@typescript/TSearch';

interface Props {
  open: boolean;
  onClose: () => void;
}

function HelpCenter({ open, onClose }: Props) {
  const { border1pxSecondaryLight } = useStyles();

  const [searchText, setSearchText] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchResults, setSearchResults] = useState<THelpCenterSearchResult[]>(
    [],
  );

  const handleSearchResults = async () => {
    try {
      setIsLoading(true);
      const response = await hubspotSearch(searchText);
      const results: THelpCenterSearchResult[] = response.results.map(
        (result: THelpCenterSearchResult) => ({
          url: result.url,
          category: result.category,
        }),
      );

      setSearchResults(results.slice(0, 3));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <Modal open={open} onClose={onClose}>
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <ModalContainer>
          <HeaderModal
            p="14px !important"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h1" sx={{ fontSize: '17px' }}>
              Help Center
            </Typography>
            <CloseBtn onClick={onClose} sx={{ position: 'unset' }}>
              <CloseIcon sx={{ width: 24, height: 24, opacity: 0.5 }} />
            </CloseBtn>
          </HeaderModal>
          <Box
            p="14px"
            borderBottom={border1pxSecondaryLight}
            sx={{
              minHeight: '394px',
            }}
          >
            <Box
              className="SearchBar"
              sx={{
                backgroundColor: 'background.default',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '6px',
                maxHeight: '36px',
              }}
            >
              <Svgs.Search
                style={{
                  paddingLeft: '0.5rem',
                  opacity: 0.5,
                  width: '30px',
                  height: '30px',
                }}
              />
              <SearchBar
                placeholder="Search..."
                onChange={(event) => setSearchText(event.target.value)}
              />
            </Box>
            <Box
              sx={{
                display: 'grid',
                gap: '14px',
                mt: '14px',
              }}
            >
              {searchText ? (
                <SearchResults results={searchResults} isLoading={isLoading} />
              ) : (
                <>
                  <LinkBox
                    href="https://docs.legion.ventures/otc"
                    title="Documentation"
                    description="Open Legion Ventures Docs"
                  />
                  <LinkBox
                    href="https://docs.legion.ventures/otc"
                    title="Guides"
                    description="Open Legion Ventures Docs"
                  />
                  <LinkBox
                    href="https://docs.legion.ventures/otc"
                    title="How to Raise a Ticket"
                    description="Open Ticket Guide"
                  />
                  <LinkBox
                    href="https://docs.legion.ventures/otc"
                    title="Support"
                    description="Open Legion Ventures Guide"
                  />
                </>
              )}
            </Box>
          </Box>
          <Box
            m="14px"
            border={border1pxSecondaryLight}
            borderRadius="6px"
            sx={{
              cursor: 'pointer',
              ':hover': { backgroundColor: 'background.default' },
            }}
          >
            <a
              href="https://discord.gg/mh9NjD6D"
              target="_blank"
              style={{ color: 'unset', textDecoration: 'unset' }}
              rel="noreferrer"
            >
              <Box
                sx={{
                  p: '0.7rem',
                  display: 'flex',
                }}
              >
                <Svgs.Discord
                  style={{
                    marginRight: '0.5rem',
                    marginTop: '0.2rem',
                  }}
                />
                <Box>
                  <Typography
                    sx={{ fontSize: '13px', fontWeight: 700, mb: '0.3rem' }}
                  >
                    Join our Discord
                  </Typography>
                  <Typography
                    sx={{ fontSize: '13px', fontWeight: 700, opacity: '0.5' }}
                  >
                    Share feedback, requests, or ask questions
                  </Typography>
                </Box>
              </Box>
            </a>
          </Box>
          <Box
            sx={{
              mx: '14px',
              mb: '14px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <a
              className="link"
              href="https://docs.legion.ventures/otc"
              target="_blank"
              style={{
                color: 'unset',
              }}
              rel="noreferrer"
            >
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  opacity: 0.5,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                Changelog
              </Typography>
            </a>
            <a
              className="link"
              href="https://docs.legion.ventures/otc"
              target="_blank"
              style={{
                color: 'unset',
              }}
              rel="noreferrer"
            >
              <Typography
                sx={{
                  fontSize: '13px',
                  fontWeight: 500,
                  opacity: 0.5,
                  ':hover': {
                    opacity: 1,
                  },
                }}
              >
                ContactUs
              </Typography>
            </a>
          </Box>
        </ModalContainer>
      </Slide>
    </Modal>
  );
}

export default HelpCenter;
