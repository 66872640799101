import { Coin } from '@constants/CCoin';
import { z } from 'zod';

export const updateListingZod = z.object({
  id: z.string(),
  price: z.number(),
  coins: z.nativeEnum(Coin).array(),
  expireIn: z.number(),
  tokenPrice: z.number(),
  amount: z.number(),
  negotiable: z.boolean().default(false),
  discordName: z.string().optional().nullable(),
  telegramLink: z.string().optional().nullable(),
});

// eslint-disable-next-line import/no-unused-modules
export const updateListingExpirationZod = z.object({
  id: z.string(),
  expireIn: z.number(),
});

export type UpdateListingDTO = z.infer<typeof updateListingZod>;

export type UpdateListingExpirationDTO = z.infer<
  typeof updateListingExpirationZod
>;
