import CryptoJS from 'crypto-js';
import { ENV } from './myEnv';

export function encryptWithSecret(text: string): string {
  const encrypted = CryptoJS.AES.encrypt(text, ENV.secretForSlug).toString();
  const encoded = encodeURIComponent(encrypted);
  return encoded;
}

export function decryptWithSecret(text: string): string {
  const decryptedBytes = CryptoJS.AES.decrypt(text, ENV.secretForSlug);
  const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}
