const serverUrl = process.env.REACT_APP_SERVER_URL;
const serverId = process.env.REACT_APP_SERVER_ID;
const secretForSlug = process.env.REACT_APP_SLUG_SECRET;
const environment = process.env.REACT_APP_PROJECT_ENVIRONMENT;

if (!serverUrl) {
  throw Error('No Moralis serverUrl provided!');
}
if (!serverId) {
  throw Error('No Moralis serverId provided!');
}

if (!secretForSlug) {
  throw Error('No Moralis secretForSlug provided!');
}

export const ENV = {
  serverId,
  serverUrl,
  secretForSlug,
  environment,
};
