import { z } from 'zod';
import { paginationZod } from './pagination.dto';

export const idZod = z.object({
  id: z.string(),
});

export const idWithPaginationZod = z
  .object({
    id: z.string(),
  })
  .merge(paginationZod);

export const nameZod = z.object({
  name: z.string(),
});

export const nameZodWithPagination = nameZod.merge(paginationZod);

export type IdDTO = z.infer<typeof idZod>;
export type IdWithPaginationDTO = z.infer<typeof idWithPaginationZod>;
export type NameWithPaginationDTO = z.infer<typeof nameZodWithPagination>;
export type NameDTO = z.infer<typeof nameZod>;
