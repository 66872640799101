/* eslint-disable no-console */
import AdminUserAllocationsTable from '@components/admin/AdminUserAllocationsTable';
import AdminUserInvestmentsTable from '@components/admin/AdminUserInvestmentsTable';
import AdminUserListingsTable from '@components/admin/AdminUserListingsTable';
import AdminUserOffersTable from '@components/admin/AdminUserOffersTable';
import AdminUserTxTable from '@components/admin/AdminUserTxTable';
import { CloudFunctions } from '@constants/CCloudFunctions';
import useUser from '@hooks/useUser';
import { Box, Switch, Typography } from '@mui/material';
import { FieldStyled } from '@styles/modal/SModal';
import { MainBox } from '@styles/shared/SBoxes';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import { TTransaction } from '@typescript/models/TradeTransaction.model';
import { TTransactionInvestment } from '@typescript/models/InvestmentTransaction.model';
import { MetamaskError } from '@utils/errors';
import Moralis from 'moralis-v1';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetSet } from 'react-use';
import { TAllocation } from '@typescript/models/Allocation.model';
import useNotification from '@hooks/useNotification';

function AdminPanel() {
  const { isAdmin } = useUser();

  const navigate = useNavigate();

  const { notifyError } = useNotification();

  const [hashInput, setHashInput] = useState<string>('');

  const [getUserWallet, setUserWallet] = useGetSet('');

  const [txRows, setTxRows] = useState<TTransaction[]>([]);

  const [allocationRows, setAllocationRows] = useState<TAllocation[]>([]);

  const [listingsRows, setListingsRows] = useState<TListing[]>([]);

  const [offersRows, setOffersRows] = useState<TOffer[]>([]);

  const [investmentsTx, setInvestmentsTx] = useState<TTransactionInvestment[]>(
    [],
  );

  const [getListingsFilter, setListingsFilter] = useGetSet({
    onlyActive: false,
    onlyWithTx: false,
  });

  const [getOffersFilter, setOffersFilter] = useGetSet({
    onlyActive: false,
    onlyWithTx: false,
  });

  const findUserData = async () => {
    setUserWallet(hashInput);
    try {
      const userTx = (await Moralis.Cloud.run(CloudFunctions.GET_USER_Tx, {
        address: getUserWallet(),
      })) as TTransaction[];

      setTxRows(userTx);
      const userAllocations = (await Moralis.Cloud.run(
        CloudFunctions.GET_USER_ALLOCATIONS,
        {
          address: getUserWallet(),
        },
      )) as TAllocation[];
      setAllocationRows(userAllocations);

      const userListings = (await Moralis.Cloud.run(
        CloudFunctions.GET_USER_LISTINGS,
        {
          address: getUserWallet(),
        },
      )) as TListing[];

      setListingsRows(userListings);

      const userOffers = (await Moralis.Cloud.run(
        CloudFunctions.GET_USER_OFFERS,
        {
          address: getUserWallet(),
        },
      )) as TOffer[];

      setOffersRows(userOffers);

      const userInvestmentsTx = (await Moralis.Cloud.run(
        CloudFunctions.GET_USER_INVESTMENT_TX,
        {
          address: getUserWallet(),
        },
      )) as TTransactionInvestment[];

      setInvestmentsTx(userInvestmentsTx);
    } catch (e) {
      const error = e as MetamaskError;
      notifyError(error.message);
      setTxRows([]);
      setAllocationRows([]);
      setListingsRows([]);
      setOffersRows([]);
      setInvestmentsTx([]);
    } finally {
      setListingsFilter({ onlyActive: false, onlyWithTx: false });
      setOffersFilter({ onlyActive: false, onlyWithTx: false });
    }
  };

  const transactionAction = useCallback((tx: TTransaction) => {
    console.log(`Not implemented, ${tx}`);
  }, []);

  const allocationAction = useCallback((allocation: TAllocation) => {
    console.log(`Not implemented, ${allocation}`);
  }, []);

  const listingAction = useCallback((listing: TListing) => {
    console.log(`Not implemented, ${listing}`);
  }, []);

  const offerAction = useCallback((offer: TOffer) => {
    console.log(`Not implemented, ${offer}`);
  }, []);

  const txInvestmentAction = useCallback((tx: TTransactionInvestment) => {
    console.log(`Not implemented, ${tx}`);
  }, []);

  const changeActiveListingsFilter = async () => {
    setListingsFilter({
      onlyActive: !getListingsFilter().onlyActive,
      onlyWithTx: false,
    });

    const userListings = (await Moralis.Cloud.run(
      CloudFunctions.GET_USER_LISTINGS,
      {
        address: getUserWallet(),
        onlyActive: getListingsFilter().onlyActive,
      },
    )) as TListing[];

    setListingsRows(userListings);
  };

  const changeListingsWithTxFilter = async () => {
    setListingsFilter({
      onlyWithTx: !getListingsFilter().onlyWithTx,
      onlyActive: false,
    });

    const userListings = (await Moralis.Cloud.run(
      CloudFunctions.GET_USER_LISTINGS,
      {
        address: getUserWallet(),
        onlyWithTx: getListingsFilter().onlyWithTx,
      },
    )) as TListing[];

    setListingsRows(userListings);
  };

  const changeActiveOffersFilter = async () => {
    setOffersFilter({
      onlyActive: !getOffersFilter().onlyActive,
      onlyWithTx: false,
    });

    const userOffers = (await Moralis.Cloud.run(
      CloudFunctions.GET_USER_OFFERS,
      {
        address: getUserWallet(),
        onlyActive: getOffersFilter().onlyActive,
      },
    )) as TOffer[];

    setOffersRows(userOffers);
  };

  const changeOffersWithTxFilter = async () => {
    setOffersFilter({
      onlyWithTx: !getOffersFilter().onlyWithTx,
      onlyActive: false,
    });

    const userOffers = (await Moralis.Cloud.run(
      CloudFunctions.GET_USER_OFFERS,
      {
        address: getUserWallet(),
        onlyWithTx: getOffersFilter().onlyWithTx,
      },
    )) as TOffer[];

    setOffersRows(userOffers);
  };

  useEffect(() => {
    if (isAdmin === false) {
      navigate('/');
    }
  }, [isAdmin, navigate]);

  return (
    <MainBox
      sx={{
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <FieldStyled
          fullWidth
          label="User hash"
          value={hashInput}
          onChange={(event) =>
            setHashInput(event.target.value.toLowerCase().trim())
          }
          placeholder="Paste the hash.."
        />
        <ButtonGradientDark
          sx={{ width: '150px' }}
          onClick={findUserData}
          disabled={!hashInput}
        >
          Find Data
        </ButtonGradientDark>
      </Box>
      <Box>
        <Typography mb={1} ml={1} variant="h2" sx={{ fontWeight: 'bold' }}>
          User Transactions
        </Typography>
        <AdminUserTxTable
          action={transactionAction}
          rows={txRows}
          userWallet={getUserWallet()}
        />
      </Box>
      <Box>
        <Typography mb={1} ml={1} variant="h2" sx={{ fontWeight: 'bold' }}>
          User Allocations
        </Typography>
        <AdminUserAllocationsTable
          action={allocationAction}
          rows={allocationRows}
        />
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography ml={1} variant="h2" sx={{ fontWeight: 'bold' }}>
            User Listings
          </Typography>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Typography>Only Active</Typography>
            <Switch
              color="warning"
              checked={getListingsFilter().onlyActive}
              onChange={changeActiveListingsFilter}
            />
            <Typography>Only With Transaction</Typography>
            <Switch
              color="warning"
              checked={getListingsFilter().onlyWithTx}
              onChange={changeListingsWithTxFilter}
            />
          </Box>
        </Box>
        <AdminUserListingsTable action={listingAction} rows={listingsRows} />
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography ml={1} variant="h2" sx={{ fontWeight: 'bold' }}>
            User Offers
          </Typography>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Typography>Only Active</Typography>
            <Switch
              color="warning"
              checked={getOffersFilter().onlyActive}
              onChange={changeActiveOffersFilter}
            />
            <Typography>Only With Transaction</Typography>
            <Switch
              color="warning"
              checked={getOffersFilter().onlyWithTx}
              onChange={changeOffersWithTxFilter}
            />
          </Box>
        </Box>
        <AdminUserOffersTable
          action={offerAction}
          rows={offersRows}
          userWallet={getUserWallet()}
        />
      </Box>
      <Box>
        <Typography mb={1} ml={1} variant="h2" sx={{ fontWeight: 'bold' }}>
          User Investments
        </Typography>
        <AdminUserInvestmentsTable
          action={txInvestmentAction}
          rows={investmentsTx}
        />
      </Box>
    </MainBox>
  );
}

export default AdminPanel;
