import { z } from 'zod';
import { Coin } from '@constants/CCoin';
import { ListingTradeStatus, OfferTradeStatus } from '@constants/CDataStatus';
import { userZod } from './User.model';
// eslint-disable-next-line import/no-cycle
import { offerZod } from './Offer.model';
import { transactionZod } from './TradeTransaction.model';
import { projectZod } from './Project.model';

const listingOffersZod = z.object({
  ownedListingOffer: z.lazy(() => offerZod).optional(),
  listingHasOffers: z.boolean().optional(),
});

const listingTradeStatusZod = z.nativeEnum(ListingTradeStatus);

export const offerTradeStatusZod = z.nativeEnum(OfferTradeStatus);

const offersStatsZod = z.object({
  activeMaxOfferedPrice: z.number(),
  activeOfferTokenPrice: z.number(),
  allTimeMaxOfferedPrice: z.number(),
});

export const baseListingZod = z.object({
  id: z.string(),
  coins: z.nativeEnum(Coin).array(),
  expireIn: z.number(),
  expireAt: z.string().datetime(),
  price: z.number(),
  tokenPrice: z.number(),
  user: userZod,
  negotiable: z.boolean(),
  isDeleted: z.boolean(),
  transactionInProgress: z.boolean(),
  amount: z.number(),
  type: z.number(),
  isExpired: z.boolean(),
  worth: z.number(),
  project: projectZod,
  acceptedCoin: z.string().optional(),
  discordName: z.string().optional(),
  telegramLink: z.string().optional(),
  acceptedBy: userZod.optional(),
  transaction: transactionZod.optional(),
  offersStats: offersStatsZod,
  tradeStatus: z.union([listingTradeStatusZod, offerTradeStatusZod]).optional(),
});

export const listingZod = z.object({
  ...baseListingZod.shape,
  ...listingOffersZod.shape,
});

export type Day = number;

export type TListing = z.infer<typeof listingZod>;
