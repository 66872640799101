import { z } from 'zod';

export const paginationZod = z.object({
  page: z.number(),
  pageSize: z.number(),
});

export type PaginationDTO = z.infer<typeof paginationZod>;

export function resourceWithPaginatedZod<ResourceType extends z.ZodTypeAny>(
  resource: ResourceType,
) {
  return z.object({
    count: z.number(),
    resource: z.array(resource),
  });
}
