import { Box, styled } from '@mui/material';

export const SelectMenuProps = {
  sx: {
    padding: '10px',
    top: '10px',
    left: '0px',
    '.MuiPaper-root': {
      backgroundColor: '#111124',
      borderRadius: '6px',
      minWidth: '130px !important',
    },
    '.MuiList-root': {
      padding: '0',
      backgroundColor: '#111124',
    },
    '.Mui-selected': {
      backgroundColor: '#181833 !important',
    },
    '.MuiButtonBase-root': {
      backgroundColor: '#111124',
      padding: '0.5rem 1rem',
      borderBottom: '1px solid #212141',
      color: '#B4C8EA',
      ':hover': {
        backgroundColor: '#181833',
      },
    },
  },
};

export const SearchModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '#111124',
  borderRadius: '6px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.secondary.light,
  width: '100%',
  height: '100%',
  ':focus': {
    outline: 'none',
  },
  [theme.breakpoints.down('xsm')]: {},
}));
