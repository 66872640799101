import { z } from 'zod';
import { userZod } from './User.model';
// eslint-disable-next-line import/no-cycle
import { projectZod } from './Project.model';

export const allocationZod = z.object({
  id: z.string(),
  excelWalletAddress: z.string().optional(),
  unlockedTokens: z.number(),
  unlockedValue: z.number(),
  lockedTokens: z.number(),
  lockedValue: z.number(),
  totalTokens: z.number(),
  investmentAmount: z.number(),
  investmentAmountWithFee: z.number(),
  percentage: z.number(),
  assetWorth: z.number(),
  social: z.string().optional(),
  project: z.lazy(() => projectZod),
  contribution: z.number(),
  user: userZod.optional(),
});

export type TAllocation = z.infer<typeof allocationZod>;

export interface TMyTotalAllocationsValues {
  totalAssetWorth: number;
  totalLockedValue: number;
  totalUnlockedValue: number;
  totalInvestment: number;
}
